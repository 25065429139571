<template>
  <v-container fluid fill-height>
    <v-row justify="center">

      <v-dialog v-model="temporaryCommission" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission Temporaire</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addTemporaryCommitee">
              <v-text-field
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission temporaire"
                  :rules="nameCommissionTemp"
                  v-model="temporaryCommissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  v-model="selectedTemporaryCommiteeOptionImage"
                  :items="options"
                  item-title="text"
                  item-value="value"
                  label="Choisir une option"
                  outlined
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #title>
                      <span class="custom-image-wrapper">
                        <img :src="item.raw.logo" class="custom-image"  alt=""/>
                      </span>
                      <span>{{ item.raw.text }}</span>
                    </template>
                  </v-list-item>
                </template>

              </v-select>

              <div v-if="selectedTemporaryCommiteeOptionImage === 'v-select'">
                <v-select
                    :items="defaultImages"
                    item-title="title"
                    item-value="id"
                    v-model="selectedImageTemporaryId"
                    class="custom-select"
                >
                  <template #item="{ item, props }">
                    <v-list-item v-bind="props" class="custom-list-item">
                      <template #title>
                        <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image"  alt=""/>
                        </span>
                          <span class="custom-item-text">{{ item.title }}</span>
                        </div>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <div v-else-if="selectedTemporaryCommiteeOptionImage === 'input-file'">
                <v-file-input label="File input" v-model="selectedTemporaryCommiteeCustomImage" variant="solo" accept="image/*"></v-file-input>
              </div>
              <v-text-field
                  v-model="startDate"
                  type="date"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="startTime"
                  label="Heure de début"
                  type="time"
                  prepend-icon="mdi-clock"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endDate"
                  type="date"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endTime"
                  label="Heure de fin"
                  type="time"
                  prepend-icon="mdi-clock"
                  @change="validateTimes"
                  required
              ></v-text-field>
              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
                  :disabled="isErrorDateMessage"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="isErrorDateMessage"
                  type="error"
                  dismissible
              >
                {{ errorDateMessage }}
              </v-alert>
              <v-alert
                  v-if="message && !isErrorDateMessage"
                  :type="isTemporaryCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="temporaryCommission = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addCommitee">
              <v-text-field
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission"
                  :rules="nameCommission"
                  v-model="commissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  v-model="selectedCommiteeOptionImage"
                  :items="options"
                  item-title="text"
                  item-value="value"
                  label="Choisir une option"
                  outlined
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #title>
                      <span class="custom-image-wrapper">
                        <img :src="item.raw.logo" class="custom-image"  alt=""/>
                      </span>
                      <span>{{ item.raw.text }}</span>
                    </template>
                  </v-list-item>
                </template>

              </v-select>

              <div v-if="selectedCommiteeOptionImage === 'v-select'">
                <v-select
                    :items="defaultImages"
                    item-title="title"
                    item-value="id"
                    v-model="selectedImageCommiteeId"
                    class="custom-select"
                >
                  <template #item="{ item, props }">
                    <v-list-item v-bind="props" class="custom-list-item">
                      <template #title>
                        <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image"  alt=""/>
                        </span>
                          <span class="custom-item-text">{{ item.title }}</span>
                        </div>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <div v-else-if="selectedCommiteeOptionImage === 'input-file'">
                <v-file-input label="File input" v-model="selectedCommiteeCustomImage" variant="solo" accept="image/*"></v-file-input>
              </div>

              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="message"
                  :type="isCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showDialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-col cols="12" sm="3" v-if="!isMobile || !selectedChat">
        <v-card>
          <v-card-title class="text-center">Alhambradote Chat</v-card-title>
          <v-col class="text-center mb-4 d-flex justify-center">
            <v-menu offset-y activator="parent">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="white" left>mdi-account-multiple-plus-outline</v-icon>
                  Choisir une commission
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="showDialog = true" class="py-1 text-center justify-center">
                  <v-list-item-title>Ajouter une commission</v-list-item-title>
                </v-list-item>
                <v-list-item @click="checkPrivileges" class="py-1 text-center justify-center">
                  <v-list-item-title>Ajouter une commission temporaire</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-alert
              v-if="showAlert"
              border="top"
              type="error"
              variant="outlined"
              prominent
              dismissible
              @input="showAlert = false"
          >
            Vous n'avez pas les droits d'ajouter une commission temporaire
            <v-progress-linear
                v-if="progress < 100"
                :model-value="progress"
                color="deep-orange"
                height="10"
                striped
            ></v-progress-linear>
          </v-alert>
          <v-card class="mx-auto bg-blue-darken-1" max-width="450">
            <v-card-title class="text-center">Commission</v-card-title>
            <v-list>
              <v-list-item v-for="item in createdCommissions" :key="item.id" @click="selectChat(item)">
                <div class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img :src="item.prependAvatar" width="50" height="50"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title class="ml-2">{{ item.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card class="mx-auto bg-blue-darken-1" max-width="450">
            <v-card-title class="text-center">Commission temporaire</v-card-title>
            <v-list>
              <v-list-item v-for="item in tempCommissions" :key="item.id" v-show="item.isOpen" @click="selectChat(item)">
                <div class="d-flex align-center">
                  <v-list-item-avatar>
                    <v-img :src="item.prependAvatar" width="50" height="50"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-title class="ml-2">{{ item.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" v-if="!isMobile || selectedChat" class="d-flex flex-column" style="height: 100vh;">
        <v-card class="flex-grow-1 d-flex flex-column" style="height: 100%;">
          <v-card-title class="text-center">
            <v-btn v-if="isMobile" @click="deselectChat" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            {{ selectedChatTitle }}
          </v-card-title>

          <v-list class="flex-grow-1" ref="messageList">
            <div v-for="(message, index) in currentMessages" :key="index" @contextmenu.prevent="showMesageContextMenu($event, index)"
                 :style="{ justifyContent: isMessageSentByCurrentUser(message) ? 'flex-end' : 'flex-start', display: 'flex' }">
              <div v-if="shouldShowDateHeader(index)">
                <v-divider></v-divider>
                <v-row class="text-center">
                  <v-col cols="12">
                    <small class="custom-message-date">{{ getFormattedDate(currentMessages[index].date) }}</small>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>

              <v-col cols="auto">
                <v-card flat :class="isMessageSentByCurrentUser(message) ? 'sent-message' : 'received-message'">
                  <v-card-text>
                    <div v-if="message.showEmojiPicker">
                      <emoji-picker @emoji-click="(event) => addLikedEmoji(event, index)"></emoji-picker>
                    </div>

                    <div v-if="showMenu && selectedMessageIndex === index" :style="{ top: `${menuY}px`, left: `${menuX}px` }">
                      <ul style="list-style: none; padding: 0; margin: 0;">
                        <li @click="toggleLikedEmojiPicker(selectedMessageIndex)" class="menu-item">
                          <v-icon>mdi-emoticon-plus-outline</v-icon>
                        </li>
                        <li @click="pinMessage(selectedMessageIndex)" class="menu-item">
                          <v-icon>mdi-pin</v-icon>
                        </li>
                      </ul>
                    </div>

                    <div class="message-container">
                      <div class="message-name">{{ getName(message) }}</div>

                      <div v-if="message.audio" class="message-audio">
                        <audio :src="message.audio" controls style="width: 100%;"></audio>
                      </div>

                      <div v-if="message.contentMessage" class="message-text">{{ message.contentMessage }}</div>

                      <div class="message-date">{{ message.date }}</div>
                      <span v-if="message.emoji" class="emoji" @click="removeEmoji(index)">{{ message.emoji }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </div>
          </v-list>
          <div>
            <v-row justify="end" style="padding: 16px;">
              <div v-if="showEmojiPicker && !showAudioPlayer">
                <emoji-picker @emoji-click="addEmoji"></emoji-picker>
              </div>

              <v-col>
                <v-text-field
                    v-model="newMessage"
                    label="Entrez votre message"
                    prepend-icon="mdi-plus"
                    @click:prepend="toggleEmojiPicker"
                    solo
                    clearable
                    style="width: 100%;"
                    @keydown.enter.prevent="sendMessage"
                    @input="onTyping"
                    :readonly="showAudioPlayer"
                >
                  <v-btn @click="removeAudio" v-if="showAudioPlayer"><v-icon>mdi-delete</v-icon></v-btn>
                  <audio
                      v-if="showAudioPlayer"
                      :src="audioUrl"
                      controls
                      style="margin-top: 16px;"
                  ></audio>
                </v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="startRecording" :disabled="isRecording || !!newMessage" color="primary">
                  <v-icon>mdi-microphone-plus</v-icon>
                </v-btn>
                <v-btn @click="stopRecording" :disabled="!isRecording" color="secondary" class="mr-4">
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
                <v-btn @click="sendMessage" color="primary" height="56"> <v-icon>mdi-send</v-icon></v-btn>
              </v-col>
              <div class="typing" v-if="typingUser">
                {{ typingUser }} est en train d'écrire
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>
            </v-row>
          </div>

          <div v-if="currentPinMessages.length > 0" class="pinned-messages">
            <h3>Pinned Messages:</h3>
            <ul>
              <li v-for="(pinnedMsg, pIndex) in currentPinMessages" :key="pIndex">
                {{ pinnedMsg.user }} {{ pinnedMsg.date }}
                <audio v-if="pinnedMsg.audio" :src="pinnedMsg.audio" controls style="width: 100%;"></audio>
                <div v-if="pinnedMsg.contentMessage">{{ pinnedMsg.contentMessage }}</div>
                <span v-if="pinnedMsg.emoji">{{ pinnedMsg.emoji }}</span>
                <button @click="unpinMessage(pIndex)"><v-icon>mdi-pin-off</v-icon></button>
              </li>
            </ul>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import {ref, computed, nextTick, watch, onBeforeMount, onMounted} from 'vue';
import { checkAuthentication } from "@/LoginCheck";
import {displayedNotifications} from "@/components/notifications";
import axios from "axios";
import urls from "@/config/config";
import 'emoji-picker-element';

const nameCommission = ref([
  value => {
    if (value) return true;
    return 'Entrez votre nom de commission';
  },
]);
const nameCommissionTemp = ref([
  value => {
    if (value) return true;
    return 'Entrez votre nom de commission temporaire';
  },
]);
const socket = ref(null);
let typingUser = ref(null);
const newMessage = ref('');
const createdCommissions = ref([]);
const defaultImages = ref([
  {id: 1, prependAvatar: '/img/animation.png', title: 'spectacle'},
  {id: 2, prependAvatar: '/img/communication.png', title: 'partage'},
  {id: 3, prependAvatar: '/img/finance.png', title: 'finance'},
  {id: 4, prependAvatar: '/img/restauration.png', title: 'restauration'},
  {id: 5, prependAvatar: '/img/global-communication.png', title: 'monde'},
]);

const showEmojiPicker = ref(false);
const tempCommissions = ref([]);
const selectedCommiteeCustomImage = ref(null)
const selectedCommiteeOptionImage = ref("");
const selectedTemporaryCommiteeOptionImage = ref("");
const options = ref([
  { value: 'v-select', logo : '/img/logo.png',text: 'Choisir des logos par défaut' },
  { value: 'input-file', logo:  '/img/custom-image.png',text: 'Choisir une image personnalisée' }
]);
const selectedTemporaryCommiteeCustomImage = ref(null)
const selectedChat = ref(null);
const messageList = ref(null);
const isMobile = ref(false);
const mediaRecorder = ref(null);
const isRecording = ref(false);
const audioChunks = ref([]);
const audioUrl = ref(null);
const messages = ref({});
const broadcastChannel = ref(new BroadcastChannel('chat'));
const broadcastChannelNotifs = ref(new BroadcastChannel('notifications'));
const selectedImageCommiteeId = ref(null);
const selectedImageTemporaryId = ref(null);
const currentUser = ref(null);
const currentEmail = ref(null);
const commissionName = ref('');
const temporaryCommissionName = ref('');
const typingTimeout = ref(null);
const temporaryImage = ref(null);
const isErrorDateMessage = ref(false);
const errorDateMessage = ref('');
const message = ref('');
const isCommissionCreated = ref(false);
const startDate = ref('');
const endDate = ref('');
const startTime = ref('');
const endTime = ref('');
const progress = ref(0);
const showDialog = ref(false);
const temporaryCommission = ref(false);
const showAlert=ref(false);
const userHasPrivilege=ref(false);
const isTemporaryCommissionCreated = ref(false);
const pinnedMessages = ref({});
const showMenu = ref(false);
const menuX = ref(0);
const menuY = ref(0);
const selectedMessageIndex = ref(null);
const showAudioPlayer = ref(false);
const responseMessage = ref('');
const blobAudio = ref(null)

const selectedChatTitle = computed(() => selectedChat.value ? selectedChat.value.title : 'Commission Globale');
const currentMessages = computed(() => selectedChat.value ? messages.value[selectedChat.value.id] || [] : []);
const currentPinMessages = computed(() => selectedChat.value ? pinnedMessages.value[selectedChat.value.id] || [] : []);

const saveAudio = async () => {
  const messageData = {
    channelID: selectedChat.value.id,
    channel: selectedChat.value.title,
    message: {
      contentMessage: newMessage.value,
      user: currentUser.value,
      userEmail: currentEmail.value,
    },
  };

  const formData = {
    user_email: messageData.message.userEmail,
    commitee_name: messageData.channel,
    content: "",
    send_date: new Date().toLocaleString(),
    audio: blobAudio.value,
  };

  console.log("Preparing to send audio file:", blobAudio.value);

  const formPayload = new FormData();
  formPayload.append('user_email', formData.user_email);
  formPayload.append('commitee_name', formData.commitee_name);
  formPayload.append('content', formData.content);
  formPayload.append('send_date', formData.send_date);
  formPayload.append('audio', formData.audio);

  axios.post(urls.save_audio, formPayload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
      .then((response) => {
        console.log("Server response:", response.data);
        const message = {
          contentMessage: formData.content,
          date: formData.send_date,
          user: messageData.message.user,
          pinMessage:'',
          audio:'/audio_upload/' + response.data.file,
          read:false
        };
        console.log("before add message audio",message.audio)
        addMessage(messageData.channelID, message, true);
        addNotification(message.contentMessage, messageData.channel, message.user, message.date,message.audio,[true,message]);
        showNotification(message.contentMessage, message.user, messageData.channel);
        newMessage.value = '';
        responseMessage.value = 'File uploaded successfully: ' + response.data;
      })
      .catch((error) => {
        console.log("Error response:", error.response?.data || error.message);
        responseMessage.value = 'Failed to upload file: ' + (error.response?.data || error.message);
      });

};
const formatToISO = (dateStr) => {
  const [datePart, timePart] = dateStr.split(' ');
  const [day, month, year] = datePart.split('-');
  return `${year}-${month}-${day}T${timePart}`;
};

const shouldShowDateHeader = (index) => {
  if (index === 0) {
    return true;
  }
  const formattedDate = formatToISO(currentMessages.value[index].date.replaceAll("/", "-"));
  const formattedPreviousDate = formatToISO(currentMessages.value[index - 1].date.replaceAll("/", "-"));

  const currentMessageDate = new Date(formattedDate).toDateString();
  const previousMessageDate = new Date(formattedPreviousDate).toDateString();

  return currentMessageDate !== previousMessageDate;
};

const getFormattedDate = (messageDate) => {

  const today = new Date();
  const formattedDate = formatToISO(messageDate.replaceAll("/", "-"));
  const messageDay = new Date(formattedDate);

  const todayDate = new Date(today.toDateString());
  const messageDayDate = new Date(messageDay.toDateString());
  const diffInDays = Math.floor((todayDate - messageDayDate) / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    return 'Aujourd\'hui';
  } else if (diffInDays === 1) {
    return 'Hier';
  } else if (diffInDays === 2) {
    return 'Avant-Hier';
  }
  else {
    return messageDay.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' });
  }
};

const toggleEmojiPicker = () => {
  showEmojiPicker.value = !showEmojiPicker.value;
};

const toggleLikedEmojiPicker = (index) => {
  messages.value[selectedChat.value.id][index].showEmojiPicker = !messages.value[selectedChat.value.id][index].showEmojiPicker;
};


const addLikedEmoji = (event, index) => {
  const emoji = event.detail.unicode;
  const chatId = selectedChat.value.id;
  const message = messages.value[chatId][index];

  message.emoji = emoji;
  message.showEmojiPicker = false;
  localStorage.setItem('chatMessages', JSON.stringify(messages.value));

  const messageId = message.id;
  console.log("Selected message ID:", messageId);

  const data = {
    type: 'addEmoji',
    channel: chatId,
    commiteeIdx: index,
    messageId: messageId,
    emoji: emoji
  };

  console.log("Data for emoji message:", data);

  if (socket.value && socket.value.readyState === WebSocket.OPEN) {
    socket.value.send(JSON.stringify(data));
  }

  const clonedMessage = {
    type:"addEmoji",
    commiteeIdx: index,
    messageId: messageId,
    channel: chatId,
    emoji: emoji
  };

  broadcastChannel.value.postMessage(clonedMessage);
};

const addEmoji = (event) => {
  const emoji = event.detail.unicode;
  newMessage.value += emoji;
  showEmojiPicker.value = false;
};

const startProgress = () => {
  progress.value = 0;
  const interval = setInterval(() => {
    console.log("progress bar value",progress.value);
    progress.value += 2;
    if (progress.value >= 100) {
      clearInterval(interval);
      showAlert.value = false;
    }
  }, 100);
};

const uploadImage = async (targettedImageUpload) => {

  const formData = new FormData();
  formData.append('image', targettedImageUpload);

  try {
    const response = await axios.post(urls.upload_custom_image, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      console.log("uploaded successfullly")
    }
  } catch (error) {
    console.log('Error: ' + error.message);
  }
};

const addCommitee = async () => {
  if (commissionName.value && (selectedImageCommiteeId.value || selectedCommiteeCustomImage.value)) {

    if (selectedCommiteeCustomImage.value) {
      await uploadImage(selectedCommiteeCustomImage.value);
    }

    try {
      const newCommission = {
        title: commissionName.value,
        prependAvatar: selectedImageCommiteeId.value
            ? getPrependAvatarById(selectedImageCommiteeId.value)
            : '/img/' + selectedCommiteeCustomImage.value.name,
        user_email: currentEmail.value
      };
      await axios.post(urls.create_commitee, newCommission)
          .then(response => {
            if (response.data.success) {
              console.log("commitee added", response.data.message)
              commissionName.value = '';
              selectedImageCommiteeId.value = null;
              showDialog.value = false;
              message.value = 'Commission ajoutée avec succès.';
              isCommissionCreated.value = true;
              getCommitees()
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });
    } catch (error) {
      isCommissionCreated.value = false;
      message.value = error.response.data.message;

      setTimeout(() => {
        message.value = '';
      }, 25000);
    }
  } else {
    message.value = 'Veuillez remplir tous les champs.';
    isCommissionCreated.value = false;

    setTimeout(() => {
      message.value = '';
    }, 10000);
  }
};
  const checkAllCommissions = () => {
  const now = new Date();
  tempCommissions.value.forEach(commission => {
    const eventDateTime = new Date(commission.endDate.date);
    console.log("date courante",now)
    console.log("date commission to check",eventDateTime)
    if (now > eventDateTime) {
      commission.isOpen = false;
    }
  });
  console.log("check commitee temp",tempCommissions.value)
};
const fetchUserRole = () => {
  axios.post(urls.get_user_role, {
    user_email:currentEmail.value
  })
      .then(response => {
        console.log("permission to open temp commitee", response.data.role);

        if(response.data.role === 'ROLE_PRIVILEGE')
        {
          userHasPrivilege.value = true;
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération du rôle de l\'utilisateur:', error);
      });
};

const checkPrivileges = () => {
  console.log("state user privilege",userHasPrivilege.value);
  if (userHasPrivilege.value) {
    temporaryCommission.value = true;
  } else {
    showAlert.value = true;
  }
};
const addTemporaryCommitee = async () => {
  console.log("heure début", startTime.value);
  console.log("heure fin", endTime.value);
  console.log("date début", startDate.value);
  console.log("date fin", endDate.value);

  if (!temporaryCommissionName.value || !startDate.value || !endDate.value || !startTime.value || !endTime.value) {
    message.value = 'Veuillez remplir tous les champs.';
    isTemporaryCommissionCreated.value = false;

    setTimeout(() => {
      message.value = '';
    }, 4000);

    return;
  }

  if (!isErrorDateMessage.value) {
    if (selectedTemporaryCommiteeCustomImage.value) {
      await uploadImage(selectedTemporaryCommiteeCustomImage.value);
    }

    if (new Date(`${startDate.value}T${startTime.value}`) >= new Date(`${endDate.value}T${endTime.value}`)) {
      errorDateMessage.value = 'La date de fin doit être après la date de début.';
      isErrorDateMessage.value = true;

      setTimeout(() => {
        errorDateMessage.value = '';
        isErrorDateMessage.value = false;
      }, 6000);

      return;
    }

    const newTemporaryCommission = {
      prependAvatar: selectedImageTemporaryId.value
          ? getPrependAvatarById(selectedImageTemporaryId.value)
          : '/img/' + selectedTemporaryCommiteeCustomImage.value.name,
      title: temporaryCommissionName.value,
      startDate: startDate.value,
      endDate: endDate.value,
      startTime: startTime.value,
      endTime: endTime.value,
      user_email: currentEmail.value
    };

    console.log("temp start date", startDate.value);

    axios.post(urls.create_temporary_commitee, newTemporaryCommission)
        .then(response => {
          if (response.data.success) {
            isTemporaryCommissionCreated.value = true;
            showDialog.value = false;
            temporaryImage.value = null;
            temporaryCommission.value = false;
            getCommitees();
          } else {
            message.value = 'Erreur lors de la création de la commission';
          }
        })
        .catch(error => {
          console.error('Error:', error);
          message.value = 'Erreur lors de la création de la commission';
        });
  } else {
    message.value = 'Veuillez remplir tous les champs.';
    isTemporaryCommissionCreated.value = false;

    setTimeout(() => {
      message.value = '';
    }, 4000);
  }
};
const getPrependAvatarById = (id) => {
  const image = defaultImages.value.find(img => img.id === id);
  return image ? image.prependAvatar : null;
}

const selectChat = (chat) => {
  selectedChat.value = chat;
  nextTick(() => {
    scrollToBottom();
  });
};

const deselectChat = () => {
  selectedChat.value = null;
};

const checkIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

const requestNotificationPermission  = () => {
  if (Notification.permission === 'default') {
    Notification.requestPermission().then(permission => {
      if (permission !== 'granted') {
        console.log('Notification permission denied');
      }
    });
  }
};

const connectWebSocket = () => {
  socket.value = new WebSocket('wss://backend.alhambradote.aimeroccitanie.fr:8443');

  socket.value.onopen = () => {
    console.log('WebSocket connection established');
  };
  socket.value.onmessage = (event) => {
    console.log("Message received:", event.data);
    try {
      const data = event.data;
      console.log("Parsed data:", data);

      const channel = data.channel;
      console.log("Channel:", channel);

      if (data.type === 'message') {
        handleMessage(data, channel);
      } else if (data.type === 'addEmoji') {
        handleAddEmoji(data, channel);
      } else if (data.type === 'isTyping') {
        typingUser.value = data.user;
      }
    } catch (e) {
      console.error("Error parsing JSON:", e);
    }
  };

  socket.value.onclose = () => {
    console.log('WebSocket connection closed');
  };

  socket.value.onerror = (error) => {
    console.error('WebSocket error: ', error);
  };
};

function handleMessage(data, channel) {
  if (data.message) {
    console.log("Received message:", data.message);

    const message = {
      id: data.message.id,
      title: data.message.contentMessage,
      date: data.message.date,
      user: data.message.user,
    };
    console.log("Formatted message:", message);

    addMessage(channel, message, false);

    let committeeToFind = createdCommissions.value.find(
        committee => committee.id === channel
    );

    let commissionTitle = committeeToFind ? committeeToFind.title : 'Commission not found';
    addNotification(message.title, commissionTitle, message.user, message.date, "", [false, message]);
  } else {
    console.log("No message found in the received data");
  }
}

function handleAddEmoji(data, channel) {
  console.log("Received emoji:");
  if (data.messageId && data.emoji) {
    console.log("Received emoji:", data.emoji, "for message:", data.messageId);

    const chatMessages = messages.value[channel][data.commiteeIdx];
    const message = chatMessages.find(msg => msg.id === data.messageId);

    if (message) {
      chatMessages.emoji = data.emoji;
      localStorage.setItem('chatMessages', JSON.stringify(messages.value));

      console.log("Emoji added to message:", message);
    } else {
      console.log("Message not found for ID:", data.messageId);
    }
  } else {
    console.log("Incomplete emoji data");
  }
}

const addNotification = (bodyMessage, titleCommission, sender, sentDate,audioSaved="",broadcastNotifications=[]) => {
  if (!localStorage.getItem("selectedNotifications").includes(titleCommission))
  {
    console.log("notifs to broadcast",broadcastNotifications)


    const clonedMessage = {
      type: "notification",
      channel: titleCommission,
      message: JSON.parse(JSON.stringify(broadcastNotifications[1])),
      user: currentUser.value
    };

    if (broadcastNotifications[0]) {
      broadcastChannelNotifs.value.postMessage(clonedMessage);
    }

    console.log("send notifs to all except : ", clonedMessage.user);

    if(sender !== clonedMessage.user)
    {
      console.log("sender notif in condition : ", sender);
      console.log("cloned message user notif in condition : ", clonedMessage.user);
      displayedNotifications.push({
        commission: titleCommission,
        senderName: clonedMessage.message.user,
        content: clonedMessage.message.contentMessage,
        date: clonedMessage.message.date,
        audio: audioSaved,
        read:false
      });
    }

    let storedNotifications = localStorage.getItem('notifications');

    if (!storedNotifications) {
      storedNotifications = [];
    }else{
      storedNotifications = JSON.parse(localStorage.getItem('notifications'));
    }

    const exists = storedNotifications.some(notification => notification.commission === titleCommission);

    if (!exists) {
      const contentNotifs = {commission: titleCommission, isDisabled:false};
      storedNotifications.push(contentNotifs)
      localStorage.setItem('notifications',JSON.stringify(storedNotifications));
      console.log('Notification ajoutée:', titleCommission);

      axios.post(urls.get_commitee_by_name, {
        user_email: currentEmail.value,
      })
          .then(response => {
            if (response.data.success) {
              console.log("get commitee by name",response.data)
              storedNotifications.forEach(notification => {
                response.data.creator_commitees.forEach(own_commmitee => {
                  if ((notification.commission === own_commmitee)
                      && response.data.role === "ROLE_PRIVILEGE") {
                    console.log("get creator commitee",notification)
                    notification.isDisabled = true;
                    localStorage.setItem('notifications', JSON.stringify(storedNotifications));
                  }
                });
              });
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });
    } else {
      console.log('Une notification avec cette commission existe déjà:',titleCommission);
    }

    console.log("sender notif",sender)
    axios.post(urls.add_notifs, {
      commission: titleCommission,
      senderName:sender,
      content:bodyMessage,
      date: sentDate,
      user_email:currentEmail.value,
    })
        .then(response => {
          if (response.data.success) {
            console.log("message registered into database",response.data.message)
          } else {
            message.value = 'Erreur lors de l\'envoi de message';
          }
        })
        .catch(error => {
          console.error('Error:', error);
          message.value = 'Erreur lors de l\'envoi de message';
          message.value = '';
        });

  } else {
    console.log(`Le message de la commission ${titleCommission} n'a pas été ajouté car elle est filtrée.`);
  }
};

const removeAudio = () => {
  audioUrl.value = '';
  showAudioPlayer.value = false;
};

const startRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.value = new MediaRecorder(stream);
    audioChunks.value = [];

    mediaRecorder.value.ondataavailable = (event) => {
      audioChunks.value.push(event.data);
    };

    mediaRecorder.value.onstop = () => {
      const audioBlob = new Blob(audioChunks.value, { type: 'audio/wav' });
      blobAudio.value = audioBlob
      audioUrl.value = URL.createObjectURL(audioBlob);
      showAudioPlayer.value = true
    };

    mediaRecorder.value.start();
    isRecording.value = true;
  } catch (error) {
    console.error('Erreur lors de l’accès au microphone :', error);
  }
};

const stopRecording = () => {
  if (mediaRecorder.value) {
    mediaRecorder.value.stop();
    isRecording.value = false;
  }
};

const sendMessage = async () => {
  if (showAudioPlayer.value) {
    await saveAudio();
    removeAudio();
  } else {
    if (newMessage.value.trim() && selectedChat.value) {
      const messageData = {
        channelID: selectedChat.value.id,
        channel: selectedChat.value.title,
        message: {
          contentMessage: newMessage.value,
          user: currentUser.value,
          userEmail: currentEmail.value,
          read:false
        },
      };

      if (socket.value && socket.value.readyState === WebSocket.OPEN) {
        const messageWithTimestamp = {
          ...messageData,
          message: {
            ...messageData.message,
            date: new Date().toLocaleString()
          },
          type:"message"
        };
        socket.value.send(JSON.stringify(messageWithTimestamp));
        showAudioPlayer.value = !!audioUrl.value;

        console.log("user email send message", messageData.message.userEmail)
        console.log("user commitee message", messageData.channel)
        console.log("user content send message", messageData.message.contentMessage)
        console.log('Sending message...');
        axios.post(urls.send_message, {
          user_email: messageData.message.userEmail,
          commitee_name: messageData.channel,
          content: messageData.message.contentMessage,
          send_date: new Date().toLocaleString(),
        })
            .then(response => {
              console.log('Response received:', response);
              if (response.data.success) {
                console.log("Message registered into database", response.data.message);
              } else {
                message.value = 'Erreur lors de l\'envoi de message';
              }
            })
            .catch(error => {
              console.error('Error:', error);
              message.value = 'Erreur lors de l\'envoi de message';
            });


        addMessage(messageData.channelID, messageWithTimestamp.message, true);
        addNotification(messageWithTimestamp.message.contentMessage, messageData.channel, messageWithTimestamp.message.user, messageWithTimestamp.message.date, "",[true,messageWithTimestamp.message]);
        showNotification(messageWithTimestamp.message.contentMessage, messageWithTimestamp.message.user, messageData.channel);
        newMessage.value = '';
      } else {
        console.error('WebSocket is not open. Message not sent.');
      }
    }
  }
};

const isMessageSentByCurrentUser = (message) => {
  return message.user === currentUser.value;
};

const getName = (message) => {
  return message.user;
};

const pinMessage = (index) => {
  const contentMessage = messages.value[selectedChat.value.id][index].contentMessage;
  const date = messages.value[selectedChat.value.id][index].date;
  const emoji = messages.value[selectedChat.value.id][index].emoji;
  const user = messages.value[selectedChat.value.id][index].user;
  const audio = messages.value[selectedChat.value.id][index].audio;
  const id = messages.value[selectedChat.value.id][index].id;

  const pinMessage = {
    contentMessage: contentMessage,
    date: date,
    emoji: emoji,
    user: user,
    audio: audio,
    id:id
  };

  if (!pinnedMessages.value[selectedChat.value.id]) {
    pinnedMessages.value[selectedChat.value.id] = [];
  }

  const existingMessageIndex = pinnedMessages.value[selectedChat.value.id].findIndex(
      (msg) => msg.id === pinMessage.id
  );

  if (existingMessageIndex !== -1) {
    pinnedMessages.value[selectedChat.value.id][existingMessageIndex] = {
      ...pinnedMessages.value[selectedChat.value.id][existingMessageIndex],
      ...pinMessage
    };
  } else {
    pinnedMessages.value[selectedChat.value.id].push(pinMessage);
  }

  localStorage.setItem('pinnedMessages', JSON.stringify(pinnedMessages.value));
  showMenu.value = false;
  const chatId = selectedChat.value.id;

  const message = currentMessages.value[index];
  const messageId = message.id;
  console.log("Selected message ID:", messageId);

  const data = {
    type: 'addPinnedMessage',
    channel: chatId,
    commiteeIdx: index,
    messageId: messageId,
    pin: pinMessage
  };

  console.log("Data for pin message:", data);

  if (socket.value && socket.value.readyState === WebSocket.OPEN) {
    socket.value.send(JSON.stringify(data));
  }

  const clonedMessage = {
    type:"addPinnedMessage",
    commiteeIdx: index,
    messageId: messageId,
    channel: chatId,
    pin: pinMessage
  };

  broadcastChannel.value.postMessage(clonedMessage);
};

const unpinMessage = (index) => {
  const pinnedMessages = JSON.parse(localStorage.getItem('pinnedMessages')) || {};
  const chatId = selectedChat.value.id;

  const message = currentMessages.value[index];

  const messageId = message.id;

  currentPinMessages.value.splice(index, 1);

  const data = {
    type: 'removePinnedMessage',
    channel: chatId,
    commiteeIdx: index,
    messageId: messageId,
    pin: pinnedMessages[chatId]
  };

  const pinRemovedData = {
    ...data
  };

  console.log("Data for remove pin message:", data);

  if (socket.value && socket.value.readyState === WebSocket.OPEN) {
    socket.value.send(JSON.stringify(pinRemovedData));
  }

  const clonedMessage = {
    type:"removePinnedMessage",
    commiteeIdx: index,
    messageId: messageId,
    channel: chatId,
    pin: pinnedMessages[chatId]
  };

  broadcastChannel.value.postMessage(clonedMessage);
};

const removeEmoji = (index) => {
  const messages = JSON.parse(localStorage.getItem('chatMessages'));
  const chatId = selectedChat.value.id;
  console.log("Found message at index for chat:", messages[chatId][index]);

  messages[chatId][index].emoji = "";
  localStorage.setItem('chatMessages', JSON.stringify(messages));

  currentMessages.value[index].emoji = "";

  const message = currentMessages.value[index];

  const messageId = message.id;

  const data = {
    type: 'removeEmoji',
    channel: chatId,
    commiteeIdx: index,
    messageId: messageId,
  };

  const emojiRemovedData = {
    ...data
  };

  console.log("Data for emoji message:", data);

  if (socket.value && socket.value.readyState === WebSocket.OPEN) {
    socket.value.send(JSON.stringify(emojiRemovedData));
  }

  const clonedMessage = {
    type:"removeEmoji",
    commiteeIdx: index,
    messageId: messageId,
    channel: chatId,
  };

  broadcastChannel.value.postMessage(clonedMessage);
};



const hideMessageContextMenu = () => {
  showMenu.value = false;
};

const showMesageContextMenu = (event, index) => {
  menuX.value = event.clientX;
  menuY.value = event.clientY;
  selectedMessageIndex.value = index;
  showMenu.value = true;
};

function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}

const addMessage = (channel, message, shouldBroadcast) => {
  console.log("content in message", message)

  typingUser.value = null;

  if (!messages.value[channel]) {
    messages.value[channel] = [];
  }

  const newMessage = {
    type: "message",
    ...message,
    id:generateUniqueId(),
    showEmojiPicker: false,
  };

  console.log("after add message",newMessage)

  messages.value[channel].push(newMessage);

  localStorage.setItem('chatMessages', JSON.stringify(messages.value));

  if (shouldBroadcast) {
    const clonedMessage = {
      type:"message",
      channel: channel,
      message: JSON.parse(JSON.stringify(message))
    };
    broadcastChannel.value.postMessage(clonedMessage);
  }

  nextTick(() => {
    scrollToBottom();
  });
};

const addTyping = (channel, sender) => {
  typingUser.value = sender;
  if (typingTimeout.value) {
    clearTimeout(typingTimeout.value);
  }

  if (sender !== currentUser.value) {
    console.log("Autre utilisateur en train d'écrire");
  }

  console.log("ID du canal:", channel);
  console.log("Utilisateur en train d'écrire:", sender);
  console.log("Utilisateur actuel:", typingUser.value);

  typingTimeout.value = setTimeout(() => {
    typingUser.value = null;
  }, 3000);
};

const validateTimes = () => {
  if (startDate.value && endDate.value && endTime.value && startTime.value) {
    console.log("start date", startDate.value);
    console.log('end date', endDate.value);

    const now = new Date();

    const [startHour, startMinute] = startTime.value.split(':').map(Number);
    const start = new Date(`${startDate.value}T${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`);

    const [endHour, endMinute] = endTime.value.split(':').map(Number);
    const end = new Date(`${endDate.value}T${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`);

    console.log("current date and time", now.toString());
    console.log("start date and time", start.toString());
    console.log("end date and time", end.toString());

    if (start < now || end < now) {
      isErrorDateMessage.value = true;
      console.log("is error", isErrorDateMessage.value);
      errorDateMessage.value = "Les dates ne peuvent pas être inférieures à la date courante.";

      setTimeout(() => {
        isErrorDateMessage.value = false;
        errorDateMessage.value = "";
      }, 3000);
    } else if (end < start) {
      isErrorDateMessage.value = true;
      console.log("is error", isErrorDateMessage.value);
      errorDateMessage.value = "L'heure de fin doit être après l'heure de début.";

      setTimeout(() => {
        isErrorDateMessage.value = false;
        errorDateMessage.value = "";
      }, 3000);
    }

    else {
      console.log('dates correctes');
      isErrorDateMessage.value = false;
      errorDateMessage.value = '';
    }
  }

}

watch(showAlert, (newValue) => {
  if (newValue) {
    startProgress();
  }
});

watch(currentUser, (newVal) => {
  if (newVal) {
    console.log('User data has been updated:', newVal);
    setInterval(() => {
      getCommitees();
    }, 30000);
  }
});

watch([startDate, endDate,endTime,startTime], validateTimes);


const showNotification = (title, user, commission) => {
  if (Notification.permission === 'granted') {
    new Notification("New Message", {
      body: `${commission} envoyé par ${user} au sujet de : ${title}`,
    });
  }
};

const loadMessages = () => {
  const storedMessages = localStorage.getItem('chatMessages');
  if (storedMessages) {
    messages.value = JSON.parse(storedMessages);
  }
};

const loadPinMessages = () => {
  const storedPinMessages = localStorage.getItem('pinnedMessages');
  if (storedPinMessages) {
    pinnedMessages.value = JSON.parse(storedPinMessages);
  }
};

const scrollToBottom = () => {
  if (messageList.value) {
    messageList.value.scrollTop = messageList.value.scrollHeight;
  }
};

const initializeBroadcastChannel = () => {
  broadcastChannel.value = new BroadcastChannel('chat');
  broadcastChannelNotifs.value = new BroadcastChannel('notifications');
  broadcastChannel.value.onmessage = (event) => {
    const { channel, type, message, user, commiteeIdx, pin, messageId, emoji } = event.data;

    switch (type) {
      case 'addEmoji':
        updateEmoji(channel,emoji,commiteeIdx,messageId);
        break;

      case 'removeEmoji':
        removedEmoji(channel,messageId, commiteeIdx);
        break;

      case 'addPinnedMessage':
        addPin(channel, pin);
        break;

      case 'removePinnedMessage':
        removePin(channel,pin, commiteeIdx);
        break;

      case 'message':
        addMessage(channel, message, false);
        break;

      case 'isTyping':
        addTyping(channel, user);
        break;
      default:
        console.warn(`Type d'événement inconnu: ${type}`);
        break;
    }
  };
  broadcastChannelNotifs.value.onmessage = (event) => {
    const {channel, message, user} = event.data;

    if (user !== currentUser.value) {
      console.log("Notification ajoutée:", message.contentMessage);
      addNotification(message.contentMessage, channel, user, message.date, "", [false, message]);
    } else {
      console.log("Notification ignorée car elle provient de l'utilisateur courant:", user);
    }
  };
};

const updateEmoji = (channel,emoji,commiteeIdx,messageId) => {
  console.log(`Updated emoji for message`);

  const messagesInChannel = messages.value[channel][commiteeIdx];

  console.log(`Updated message from emoji`,messagesInChannel);
  if (channel && typeof emoji !== 'undefined') {
    messagesInChannel.emoji = emoji;
    console.log(`Updated emoji ${messages.value} in channel ${channel}: ${emoji}`);
  } else {
    console.log(`Message with ID ${messageId} not found in channel ${channel}.`);
  }
};

const addPin = (channel,pin) => {
  if (!pinnedMessages.value[channel]) {
    pinnedMessages.value[channel] = [];
  }

  const existingMessageIndex = pinnedMessages.value[channel].findIndex(
      (msg) => msg.id === pin.id
  );

  if (existingMessageIndex !== -1) {
    pinnedMessages.value[channel][existingMessageIndex] = {
      ...pinnedMessages.value[channel][existingMessageIndex],
      ...pin
    };
  } else {
    pinnedMessages.value[channel].push(pin);
  }
};

const removePin = (channel, pin, commiteeIdx) => {
  console.log("pin to remove",pin[commiteeIdx]);
  const pinId = pin[commiteeIdx].id;
  if (pinnedMessages.value[channel]) {
    const existingMessageIndex = pinnedMessages.value[channel].findIndex(
        (msg) => msg.id === pinId
    );

    if (existingMessageIndex !== -1) {

      const updatedMessages = [...pinnedMessages.value[channel]];
      updatedMessages.splice(existingMessageIndex, 1);
      pinnedMessages.value[channel] = updatedMessages;
      localStorage.setItem('pinnedMessages', JSON.stringify(pinnedMessages.value));
      console.log(`Removed pinned message with ID: ${pinId} from channel: ${channel}`);
    } else {
      console.log(`Message with ID: ${pinId} not found in channel: ${channel}.`);
    }
  } else {
    console.log(`No pinned messages found for channel: ${channel}.`);
  }
};

const removedEmoji = (channel,messageId, commiteeIdx) => {
  console.log(`Updated emoji for message`);

  const messagesInChannel = messages.value[channel][commiteeIdx];

  console.log(`Updated message from emoji`,messagesInChannel);
  if (channel) {
    messagesInChannel.emoji = "";
    console.log(`Updated emoji ${messages.value}`);
  } else {
    console.log(`Message with ID ${messageId} not found in channel ${channel}.`);
  }
};

const onTyping = () => {
  if (socket.value && socket.value.readyState === WebSocket.OPEN) {
    socket.value.send(JSON.stringify({ type: 'isTyping', channel: selectedChat.value.id, user: currentUser.value }));
  }

  const clonedMessage = {
    type: "isTyping",
    channel:selectedChat.value.id,
    user: currentUser.value
  };

  broadcastChannel.value.postMessage(clonedMessage);
};

const checkUserAuthentication = async () => {
  const user = await checkAuthentication();
  currentUser.value = user.loginUser;
  currentEmail.value = user.email;

  if (user) {
    return {
      loginUser: currentUser.value,
      email: currentEmail.value
    }
  }

  return {
    loginUser: currentUser.value,
    email: currentEmail.value
  }
};

const getCommitees = () => {
  axios.get(urls.get_commitees, {
    params: {
      user_email: currentEmail.value
    }
  })
      .then(response => {
        console.log(response.data)
        if (response.data.success) {
          createdCommissions.value = response.data.commitees
          tempCommissions.value = response.data.temporaryCommitees
          tempCommissions.value.forEach(commission => {
              commission.isOpen = true;
          });
          message.value = '';
          selectedImageCommiteeId.value = null;
          commissionName.value = '';

          let globalCommission = createdCommissions.value.find(
              committee => committee.title === 'commission globale'
          );

          if (!selectedChat.value) {
            selectedChat.value = globalCommission;
          }

          checkAllCommissions();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        message.value = 'Erreur lors de la création de la récupération des data commission';
      });
}

const retrieveUserAuthData = () => {
  checkUserAuthentication()
      .then(authData => {
        currentUser.value = authData.loginUser;
        currentEmail.value = authData.email;
        if (localStorage.getItem('pageRefreshed') === 'false') {
          localStorage.setItem('pageRefreshed', 'true');
          localStorage.setItem('selectedNotifications', []);
          window.location.reload();
        }
        getCommitees();
        fetchUserRole();
      })
      .catch(error => {
        console.error('Error in checkUserAuthentication:', error);
      });
}

onMounted(() => {
  retrieveUserAuthData();
  requestNotificationPermission();
  checkIsMobile();
  connectWebSocket();
  loadMessages();
  loadPinMessages();
  initializeBroadcastChannel();
  if (showAlert.value) {
    startProgress();
  }
  window.addEventListener('click', hideMessageContextMenu);
});
onBeforeMount(() => {
  if (socket.value) {
    socket.value.close();
  }
  window.removeEventListener('resize', checkIsMobile);
  if (broadcastChannel.value) {
    broadcastChannel.value.close();
  }
});
</script>

<style scoped>
.sent-message {
  background-color: #007bff;
  color: white;
  border-radius: 15px;
  position: relative;
  overflow: visible;
}

.received-message {
  background-color: #ffa500;
  color: white;
  border-radius: 15px;
  position: relative;
  overflow: visible;
}

.message-container {
  display: flex;
  flex-direction: column;
}

.message-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.message-text {
  font-size: 1rem;
}

.message-date {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 5px;
}

.pinned-messages {
  margin-top: 20px;
}

.pinned-messages ul {
  list-style-type: none;
  padding: 0;
}

.pinned-messages li {
  margin-bottom: 10px;
}

.emoji {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: beige;
  border-radius: 50%;
  position: absolute;
  bottom: -11px;
  left: 16%;
  transform: translateX(-56%);
  width: 40px;
}

.custom-message-date{
  font-family: 'Dancing Script', cursive;
}

.menu-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: orange;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#ffa500;
  }
  28% {
    transform: translateY(-7px);
    background-color:#ffa500;
  }
  44% {
    transform: translateY(0px);
    background-color: #ffa500;
  }
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #ffa500 ;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

</style>