<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-center mb-4 d-flex justify-center">
            Liste des Messages
          </v-card-title>

            <v-data-table
                :items="messages"
                item-key="id"
                class="elevation-1"
                loading-text="Chargement en cours..."
                :loading="isLoading"
                items-per-page-text="éléments par page"
            >

              <template v-slot:[`item.contenu`]="{ item }">
                <div v-if="item.contenu.endsWith('.wav')">
                  <audio :src="`/audio_upload/${item.contenu}`" controls style="width: 100%;"></audio>
                </div>
                <div v-else>
                  {{ item.contenu }}
                </div>
              </template>
            </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import {checkAuthentication} from "@/LoginCheck";
import urls from "@/config/config";

export default {
  name: 'MessageList',
  data() {
    return {
      messages: [],
      isLoading:true,
      userEmail:'',
      headers: [
        { text: 'Content', value: 'content' },
        { text: 'Date', value: 'date' },
        { text: 'Creator', value: 'creator' },
        { text: 'Commitee', value: 'commitee' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    async fetchMessages() {
      try {
        try {
          const response = await checkAuthentication();
          this.isLoggedIn = true;
          this.userEmail = response.email;
        } catch (error) {
          console.log("Erreur lors de l'authentification:", error);
        }
        console.log("current user email",this.userEmail)
        const response = await axios.get(urls.get_messages,{
          params: {
            user_email: this.userEmail
          }
        });
        this.messages = response.data;
        if(response.data){
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
  },
  mounted() {
    this.fetchMessages();
  },
};
</script>

<style scoped>
</style>
