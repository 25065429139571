<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-alert
            v-if="passwordExists"
            text="Les deux mots de passes sont différents. Utilisez un autre mot de passe"
            title="Problème de mot de passe"
            type="error"
        >
          <v-progress-linear
              v-if="progress < 100"
              :value="progress"
              color="deep-orange"
              height="10"
              striped
          ></v-progress-linear>
        </v-alert>

        <v-alert
            v-if="isTokenExpired"
            text="Le token est expiré, veuillez refaire la procédure de réinitialisation : "
            title="Problème token expiré"
            type="error"
        >
          <a style="color:white; text-align: center; margin-top: 10px;" href="/forgot-password">Cliquez ici</a>
        </v-alert>

        <div v-if="!isTokenExpired" class="text-center">
          <h1>Réinitialisation de mot de passe</h1>
          <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
            <v-form @submit.prevent="submitNewPassword">
              <div class="text-subtitle-1 text-medium-emphasis">Nouveau mot de passe</div>
              <v-text-field
                  label="Mot de passe"
                  v-model="password"
                  :rules="newPasswordRules"
                  :append-inner-icon="visibleNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visibleNewPassword ? 'text' : 'password'"
                  density="compact"
                  placeholder="Entrez le nouveau mot de passe"
                  prepend-inner-icon="mdi-lock-outline"
                  variant="outlined"
                  @click:append-inner="visibleNewPassword = !visibleNewPassword"
              ></v-text-field>

              <div class="text-subtitle-1 text-medium-emphasis">Confirmation nouveau mot de passe</div>
              <v-text-field
                  label="Mot de passe"
                  v-model="confirmPassword"
                  :rules="newConfirmPasswordRules"
                  :append-inner-icon="visibleConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visibleConfirmPassword ? 'text' : 'password'"
                  density="compact"
                  placeholder="Confirmez le nouveau mot de passe"
                  prepend-inner-icon="mdi-lock-outline"
                  variant="outlined"
                  @click:append-inner="visibleConfirmPassword = !visibleConfirmPassword"
              ></v-text-field>

              <v-btn block class="mb-8" color="blue" size="large" variant="tonal" type="submit">
                REINITIALISER
              </v-btn>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import urls from "@/config/config";
import axios from "axios";

export default {
  props: ['token'],
  data() {
    return {
      visibleNewPassword: false,
      visibleConfirmPassword: false,
      password: '',
      confirmPassword: '',
      passwordExists: false,
      progress: 0,
      isTokenExpired: false,
      newPasswordRules: [
        value => {
          if (value) return true;
          return 'Entrez votre nouveau mot de passe';
        },
      ],
      newConfirmPasswordRules: [
        value => {
          if (value) return true;
          return 'Confirmez votre nouveau mot de passe';
        },
      ],
    };
  },
  watch: {
    passwordExists(newValue) {
      if (newValue) {
        this.startProgress();
      }
    },
  },
  mounted() {
    this.verifyToken();
  },
  methods: {
    startProgress() {
      this.progress = 0;
      const interval = setInterval(() => {
        this.progress += 2;
        if (this.progress >= 100) {
          clearInterval(interval);
          this.passwordExists = false;
        }
      }, 100);
    },
    submitNewPassword() {
      this.isLoading = true;
      axios.post(urls.getApiResetPasswordConfirmation(this.token), {
        password: this.password,
        confirm_password: this.confirmPassword,
      })
          .then(response => {
            this.isLoading = false;
            if (response.data.success) {
              this.$router.push({ name: 'Login' });
            } else {
              this.errorMessage = response.data.message || 'Password reset failed.';
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.message = "Les informations sont incorrectes";
            console.log(error.response);
            if (error.response.data.error_password === "Passwords mismatch") {
              this.passwordExists = true;
            }
            if (error.response.data.error_token === "Token has expired") {
              this.isTokenExpired = true;
            }
          });
    },
    verifyToken() {
      axios.get(urls.getApiVerifyToken(this.token))
          .then(response => {
            console.log("verify token",response)
            this.isTokenExpired = !response.data.valid;
          })
          .catch(error => {
            console.error("Erreur lors de la vérification du token", error);
            this.isTokenExpired = true;
          });
    },
  },
};
</script>
