<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-form @submit.prevent="requestPasswordReset">
          <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
            <div class="text-subtitle-1 text-medium-emphasis">Email</div>
            <v-text-field label="Email" placeholder="Entrez votre email" v-model="email" :rules="emailRules" prepend-inner-icon="mdi-email-outline" variant="outlined" density="compact" required></v-text-field>
            <v-btn block class="mb-8" :loading="isLoading" color="blue" size="large" variant="tonal" type="submit">
              {{ isLoading ? 'Envoi en cours ...' : 'ENVOYER' }}
            </v-btn>
            <v-card-text class="text-center">
              <a class="text-blue text-decoration-none" href="/login">
                Retour à la connexion
                <v-icon icon="mdi-chevron-right"></v-icon>
              </a>
            </v-card-text>
          </v-card>
          <v-alert v-if="message" :type="resetSuccessful ? 'success' : 'error'" class="mt-4">
            {{ message }}
          </v-alert>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import urls from "@/config/config";

export default {
  name: 'PasswordReset',
  data() {
    return {
      email: '',
      message: '',
      isLoading: false,
      resetSuccessful: false,
      emailRules: [
        value => {
          if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true

          return 'Entrez une adresse mail valide'
        },
      ],
    };
  },
  methods: {
    requestPasswordReset() {
      if (this.email === '') {
        this.message = 'Veuillez entrer votre email.';
        this.resetSuccessful = false;
        return;
      }

      this.isLoading = true;
      axios.post(urls.password_reset, {
        email: this.email
      })
          .then(response => {
            if (response.data.success) {
              this.message = 'Lien de réinitialisation envoyé à votre email.';
              this.resetSuccessful = true;
            } else {
              this.message = 'Email non trouvé.';
              this.resetSuccessful = false;
            }
          })
          .catch(() => {
            this.message = 'Une erreur est survenue. Veuillez réessayer plus tard.';
            this.resetSuccessful = false;
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  }
};
</script>

<style scoped>
</style>
