<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <div v-if="!stepTwo">
          <v-form @submit.prevent="validateCredentials">
            <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
              <div class="text-subtitle-1 text-medium-emphasis">Prénom</div>
              <v-text-field label="Prénom" placeholder="Entrez votre prénom" v-model="name" :rules="nameRules" prepend-inner-icon="mdi-account-outline" variant="outlined" density="compact" required></v-text-field>
              <div class="text-subtitle-1 text-medium-emphasis">Nom</div>
              <v-text-field label="Nom" placeholder="Entrez votre nom" v-model="lastname" :rules="lastNameRules" prepend-inner-icon="mdi-account-outline" variant="outlined" density="compact" required></v-text-field>
              <div class="text-subtitle-1 text-medium-emphasis">Mot de passe</div>
              <v-text-field label="Mot de passe" v-model="password" :rules="passwordRules" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'" density="compact" placeholder="Entrez votre mot de passe" prepend-inner-icon="mdi-lock-outline" variant="outlined" @click:append-inner="visible = !visible"></v-text-field>
              <v-btn block class="mb-8" :loading="isLoading" color="blue" size="large" variant="tonal" type="submit">
                {{ isLoading ? 'Connexion en cours ...' : 'Se connecter' }}
              </v-btn>
              <v-card-text class="text-center">
                <a class="text-blue text-decoration-none" href="/register">
                  Pas encore inscrit ?
                  <v-icon icon="mdi-chevron-right"></v-icon>
                </a>
                <a class="text-blue text-decoration-none" href="/forgot-password">
                  Mot de passe oublié ?
                </a>
              </v-card-text>
            </v-card>
            <v-alert v-if="message" :type="loginSuccessful ? 'success' : 'error'" class="mt-4">
              {{ message }}
            </v-alert>
          </v-form>
        </div>
        <div v-else>
          <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
              <h3 class="text-h5 text-center">Code de vérification</h3>
              <v-otp-input label="TOTP" v-model="totp" class="mb-8" variant="outlined" required></v-otp-input>
            <v-btn block class="mb-8" :loading="isLoading" color="blue" size="large" variant="tonal">
              {{ isLoading ? 'Vérification en cours...' : 'Vérifier TOTP' }}
            </v-btn>
          </v-card>
          <v-alert v-if="message" :type="totpSuccessful ? 'success' : 'error'" class="mt-4">
            {{ message }}
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import urls from "@/config/config";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  data() {
    return {
      visible: false,
      name: '',
      lastname: '',
      password: '',
      message: '',
      totp: '',
      isLoading: false,
      loginSuccessful: false,
      stepTwo: false,
      totpSuccessful: false,
      nameRules: [
        value => {
          if (value) return true
          return 'Entrez votre prénom'
        },
      ],
      lastNameRules: [
        value => {
          if (value) return true
          return 'Entrez votre nom de famille'
        },
      ],
      passwordRules: [
        value => {
          if (value) return true
          return 'Entrez votre mot de passe'
        },
      ],
    };
  },
  watch: {
    totp(newValue) {
      if (newValue.length === 6) {
        this.isLoading = true
        this.verifyTOTP();
      }
    }
  },
  methods: {
    validateCredentials() {
      axios.post(urls.login, {
        name: this.name,
        lastname: this.lastname,
        password: this.password,
      })
          .then(response => {
            if (response.data.success) {
                this.stepTwo = true;
                this.message = ""
            } else {
              this.message = "Les informations sont incorrectes.";
              this.loginSuccessful = false;
            }
          })
          .catch(() => {
            this.message = "Les informations sont incorrectes.";
            this.loginSuccessful = false;
          })
            .finally(() => {
            this.isLoading = false;
          });
    },
    verifyTOTP() {
      axios.post(urls.verify_TOTP, {
        name: this.name,
        lastname: this.lastname,
        password: this.password,
        totp: this.totp
      })
          .then(response => {
            localStorage.setItem('access', response.data.access_token);
            localStorage.setItem('refresh', response.data.refresh_token);
            localStorage.setItem('pageRefreshed', 'false');
            if (response.data.success) {
              this.message = 'Vérification TOTP réussie. Connexion en cours...';
              this.isLoading = true;
              this.totpSuccessful = true;
              setTimeout(() => {
                this.loginSuccessful = true;
                this.message = 'Connexion réussie !!!';
                const userRole = response.data.role;

                if (userRole === 'ROLE_ADMIN') {
                  this.$router.push('/admin/dashboard');
                } else {
                  this.$router.push('/chat');
                }
              }, 200);
            } else {
              this.message = "Le code TOTP est incorrecte.";
              this.loginSuccessful = false;
            }
          })
          .catch(() => {
            this.message = "Le code TOTP est incorrecte.";
            this.loginSuccessful = false;
            this.isLoading = false
          });
    },
  }
};
</script>
