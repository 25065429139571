const baseURL = 'https://backend.alhambradote.aimeroccitanie.fr/api';

const urls = {
    login: `${baseURL}/login`,
    register: `${baseURL}/register`,
    password_reset: `${baseURL}/request-password-reset`,
    get_messages: `${baseURL}/messages`,
    verify_TOTP:`${baseURL}/verify-TOTP`,
    send_message:`${baseURL}/send-message`,
    create_commitee: `${baseURL}/create-commitee`,
    get_user_role:`${baseURL}/get-user-role`,
    check_authentication:`${baseURL}/check-authentication`,
    refresh_token_request:`${baseURL}/refresh-token`,
    get_user_roles: `${baseURL}/user-roles`,
    get_users: `${baseURL}/users`,
    create_temporary_commitee: `${baseURL}/create-temporary-commitee`,
    get_commitees: `${baseURL}/commitees`,
    get_commitee_by_name: `${baseURL}/commitee-name`,
    add_notifs: `${baseURL}/add-notification`,
    add_user: `${baseURL}/add-user`,
    upload_custom_image : `${baseURL}/upload-custom-image`,
    save_audio : `${baseURL}/upload-audio`,
    get_custom_avatar : `${baseURL}/avatar/name`,

    getApiVerifyToken: (token) => `${baseURL}/verify-token/${token}`,
    getApiDeleteCommitee: (commiteeID) => `${baseURL}/delete-commitee/${commiteeID}`,
    getApiUpdateCommitee: (commiteeID) => `${baseURL}/update-commitee/${commiteeID}`,
    getApiDeleteTemporaryCommitee: (tempCommiteeID) => `${baseURL}/delete-temporary-commitee/${tempCommiteeID}`,
    getApiUpdateTemporaryCommitee: (tempCommiteeID) => `${baseURL}/update-temporary-commitee/${tempCommiteeID}`,
    getApiUpdateUser: (userID) => `${baseURL}/user/edit/${userID}`,
    getApiDeleteUser: (userID) => `${baseURL}/user/delete/${userID}`,
    getApiResetPasswordConfirmation: (token) => `${baseURL}/reset-password/${token}`,

};

export default urls;
