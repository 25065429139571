<template>
  <div>
    <v-container fluid class="d-flex flex-column align-center justify-center">
      <v-flex xs12 class="d-flex justify-center" style="text-align: center;">
        <h1>Bienvenue sur Alhambradote</h1>
      </v-flex>

      <v-flex xs12 sm6 class="d-flex justify-center">
        <blockquote class="blockquote text-xs-center" style="max-width: 600px; margin: 0;">
          Que souhaitez-vous faire ?
        </blockquote>
      </v-flex>

      <v-flex xs12 sm6 class="d-flex justify-center" style="text-align: center;">
        <v-btn color="primary" to="/register" class="mx-1">S'inscrire</v-btn>
        <v-btn to="/login" class="mx-1">Se connecter</v-btn>
      </v-flex>
    </v-container>
  </div>
</template>
<script>

export default {
  name: "LandingPage",
};

</script>
