import axios from 'axios';
import urls from "@/config/config";

export const checkAuthentication = async () => {
  try {
    const response = await axios.get(urls.check_authentication, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    });
    console.log(response);
    return {
      loginUser: response.data.user,
      email: response.data.email
    }
  } catch (error) {
    console.error('Error checking authentication:', error);
    throw error;
  }
};
