import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import PasswordReset from "@/views/auth/PasswordReset.vue";
import Home from "@/views/Home.vue";
import ChatRoom from "@/views/chat/ChatRoom.vue";
import ResetPasswordConfirmation from "@/views/auth/ResetPasswordConfirmation.vue";
import UserList from "@/views/admin/users/UserList.vue";
import MessagesList from "@/views/admin/messages/MessagesList.vue";
import CommiteeList from "@/views/admin/commitee/CommiteeList.vue";
import AdminDashboard from "@/views/admin/AdminDashboard.vue";
import NotFound from "@/views/NotFound.vue";
import {createRouter, createWebHistory} from "vue-router";

const routes = [
    { path: '/login', name: 'Login',  meta : {title : "Connexion"}, component: Login },
    { path: '/register', name: 'Register', meta : {title : "Inscription"}, component: Register },
    { path: '/forgot-password', name: 'ForgotPassword', meta : {title : "Mot de passe oublié"}, component: PasswordReset },
    { path: '/', name: 'Home', meta : {title : "Accueil"},component: Home },
    { path: '/chat', name: 'ChattingRoom', meta : {title : "Discussion"},component: ChatRoom },
    { path: '/reset-password/:token', name: 'ResetPasswordConfirmation', meta : {title : "Mot de passe confirmation"}, component: ResetPasswordConfirmation, props: true},
    { path: '/users', name: 'UserList', meta : {title : "Liste des utilisateurs"},component: UserList },
    { path: '/messages', name: 'MessagesList', meta : {title : "Liste des messages"},component: MessagesList },
    { path: '/commitees', name: 'CommiteeList', meta : {title : "Liste des commissions"},component: CommiteeList },
    { path: '/admin/dashboard', name: 'AdministrationDashboard', meta : {title : "Espace administrateur"},component: AdminDashboard },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    }
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});