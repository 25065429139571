import axios from "axios";
import urls from "@/config/config";

const register = (name, password, permission, email) => {
    return axios.post(urls.register, {
        name,
        password,
        permission,
        email
    });
};

const login = (name, lastname, password) => {
    return axios.post(urls.login, {
        name : name,
        lastname : lastname,
        password : password
    }).then(response => {
        const { access, refresh } = response.data;
        localStorage.setItem('access', access);
        localStorage.setItem('refresh', refresh);
        return response.data;
    });
};

const verifyTOTP = (name, lastname, password, totp) => {
    return axios.post(urls.verify_TOTP, {
        name:name, lastname:lastname, password:password, totp:totp
    });
};

export const logout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('pageRefreshed');
    document.location.href="/login";
};

const authService = {
    register,
    login,
    logout,
    verifyTOTP,
};

export default authService;
