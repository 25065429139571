<template>
  <v-app>
      <v-app-bar app class="navbar-toolbar">
        <v-app-bar-nav-icon
            v-if="!isLoggedIn"
            @click="sidebar = !sidebar"
            class="hidden-md-and-up"
        ></v-app-bar-nav-icon>
        <v-spacer class="hidden-md-and-up"></v-spacer>
      <div class="navbar-logo-container">
        <a href="/">
          <img src="../assets/alhambradote.png" class="navbar-logo" alt="">
        </a>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down ml-auto">
        <v-btn
            flat
            v-for="item in filteredMenuItems"
            :key="item.title"
            :to="item.path"
            @click="item.title === ''"
        >
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-menu v-if="isLoggedIn" rounded>
        <template v-slot:activator="{ props }">
          <v-btn class="text-none" stacked v-bind="props">
            <v-badge color="success" dot class="custom-badge" overlap>
              <v-avatar color="white">
                <img :src="user.initials" alt="user-login" />
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-divider></v-divider>
              <v-list>
                <v-dialog v-model="showNotifications" max-width="500">
                  <v-card>
                    <v-card-title>
                      <span class="font-weight-bold text--primary--text font-italic d-flex justify-center">Notifications</span>
                    </v-card-title>
                    <v-card-text>
                      <v-switch
                          v-if="displayNotifs.length"
                          v-model="displayFilteredNotifications"
                          :label="displayFilteredNotifications ? 'Tout afficher' : 'Afficher seulement les non lues'"
                          :color="displayFilteredNotifications ? 'primary' : 'secondary'"
                          @change="toggleFilter"
                          hide-details
                          inset
                      ></v-switch>
                      <v-list>
                        <v-list-item v-for="(notification, index) in filteredNotifs" :key="index">
                          <v-list-item-content>
                            <v-list-item-title :class="{'font-weight-bold': !notification.read}">
                              {{ notification.commission }}
                            </v-list-item-title>

                            <v-list-item-subtitle :class="{'font-weight-bold': !notification.read}">
                              {{ notification.senderName }}
                            </v-list-item-subtitle>

                            <v-list-item-title v-if="notification.content" :class="{'font-weight-bold': !notification.read}">
                              {{ notification.content }}
                            </v-list-item-title>

                            <v-list-item-title v-if="notification.audio" :class="{'font-weight-bold': !notification.read}">
                              <audio :src="notification.audio" controls style="width: 100%;"></audio>
                            </v-list-item-title>

                            <v-list-item-subtitle :class="{'font-weight-bold': !notification.read}">
                              {{ notification.date }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                                @click="markAsRead(index)"
                            >
                              <v-icon left :color="notification.read ? 'orange' : 'green'">
                                {{ notification.read ? 'mdi-message-check-outline' : 'mdi-message-badge-outline' }}
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-if="!displayNotifs.length">
                          <v-list-item-content>
                            <v-list-item-title>Pas de nouvelles notifications</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-btn  v-if="displayNotifs.length" color="primary" @click="markAllAsRead">Marquer toutes comme lues</v-btn>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="showNotifications = false">Fermer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="showManageNotifications" max-width="500">
                  <v-card>
                    <v-card-title>
                      <span class="font-weight-bold text--primary--text font-italic d-flex justify-center">Gérer les Notifications</span>
                    </v-card-title>
                    <v-card-text>
                      <v-form>
                        <v-checkbox
                            v-for="(notification, index) in notifs"
                            :key="index"
                            v-model="selectedNotifications"
                            :label="notification.commission"
                            :value="notification.commission"
                            :disabled="notification.isDisabled"
                        ></v-checkbox>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="applyNotificationSettings">Appliquer</v-btn>
                      <v-btn color="grey" @click="showManageNotifications = false">Annuler</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-list-item v-if="userRole !== 'ROLE_ADMIN'" @click.stop="showNotifications = true">
                  <v-badge color="error" :content="displayNotifs.length" offset-x="-9" offset-y="8">
                    <v-icon>mdi-bell-outline</v-icon>
                  </v-badge>
                  <v-list-item-content>
                    <v-list-item-title>Notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider v-if="userRole !== 'ROLE_ADMIN'"></v-divider>

                <v-list-item v-if="userRole !== 'ROLE_ADMIN'" @click.stop="showManageNotifications = true">
                  <v-icon>mdi-cog-transfer</v-icon>
                  <v-list-item-content>
                    <v-list-item-title>Gérer les notifications</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider v-if="userRole !== 'ROLE_ADMIN'"></v-divider>
                <v-list-item @click="logoutAuth">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Déconnexion</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        v-model="sidebar"
        app
        temporary
        class="hidden-md-and-up"
    >
      <v-list>
        <v-list-item
            v-for="item in filteredMenuItems"
            :key="item.title"
            :to="item.path"
            @click="item.title === ''"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <div id="app">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { logout } from '@/services/auth';
import { checkAuthentication } from "@/LoginCheck";
import {displayedNotifications} from "@/components/notifications";
import axios from "axios";
import urls from "@/config/config";
export default {
  name: "NavBar",
  data() {
    return {
      filter: 'all',
      sidebar: false,
      isLoggedIn: false,
      userRole: '',
      showNotifications: false,
      showManageNotifications: false,
      accessToken: localStorage.getItem('access'),
      refreshToken: localStorage.getItem('refresh'),
      checkInterval: null,
      displayFilteredNotifications: true,
      user: {
        initials: '/img/connection.png',
        fullName: '',
        email: '',
      },
      menuItems: [
        { title: 'Connexion', path: '/login', icon: 'mdi-login' },
        { title: 'Inscription', path: '/register', icon: 'mdi-account-plus' },
        { title: '' }
      ],
      notifs: JSON.parse(localStorage.getItem('notifications')),
      displayNotifs:displayedNotifications,
      selectedNotifications: [],
    };
  },
  watch: {
    selectedNotifications() {
      localStorage.setItem('selectedNotifications', this.selectedNotifications);
    },
  },
  methods: {
    toggleFilter() {
      this.filter = this.displayFilteredNotifications ? 'all' : 'unread';
    },
    async logoutAuth() {
      this.isLoggedIn = false;
      logout();
    },
    async refreshAccessToken() {
      try {
        const response = await axios.post(urls.refresh_token_request, {
          refresh_token: this.refreshToken
        });
        localStorage.setItem('access', response.data.access_token);
        this.accessToken = response.data.access_token;
      } catch (error) {
        console.error(error);
      }
    },
    markAsRead(index) {
      this.displayNotifs[index].read = true;
    },
    markAllAsRead() {
      this.displayNotifs.forEach(notification => {
        notification.read = true;
      });
    },
    isTokenNearExpiry() {
      if (!this.accessToken) {
        return true;
      }

      const decodedToken = this.parseJwt(this.accessToken);
      if (!decodedToken || !decodedToken.exp) {
        return true;
      }

      const expirationDate = new Date(decodedToken.exp * 1000);
      const currentDate = new Date();
      const timeLeftInSeconds = (expirationDate - currentDate) / 1000;

      return timeLeftInSeconds <= 10;
    },
    parseJwt(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = atob(base64Url.replace(/-/g, '+').replace(/_/g, '/'));
        return JSON.parse(decodeURIComponent(escape(base64)));
      } catch (e) {
        console.error('Erreur lors du décodage du token:', e);
        return null;
      }
    },
    fetchUserRole() {
      axios.post(urls.get_user_role, {
        user_email:this.user.email
      })
          .then(response => {
            this.userRole = response.data.role;
            console.log('Rôle de l\'utilisateur récupéré:', this.userRole);
          })
          .catch(error => {
            console.error('Erreur lors de la récupération du rôle de l\'utilisateur:', error);
          });
    },
    startTokenCheck() {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
      }

      this.checkInterval = setInterval(() => {
        if (this.isTokenNearExpiry()) {
          this.refreshAccessToken();
        }
        this.notifs = JSON.parse(localStorage.getItem('notifications'));
      }, 5000);
    },
    applyNotificationSettings() {
      this.showManageNotifications = false;
    },
    async authenticateAndFetchUser() {
        try {
          const response = await checkAuthentication();
          this.isLoggedIn = true;
          this.user.fullName = response.loginUser;
          this.user.email = response.email;

          const storedNotifications = localStorage.getItem('selectedNotifications');
          if (storedNotifications) {
            this.selectedNotifications = storedNotifications.split(',');
            console.log(this.selectedNotifications);
          }

          this.fetchUserRole();
          this.startTokenCheck();
        } catch (error) {
          this.isLoggedIn = false;
          console.log("Erreur lors de l'authentification:", error);
        }
      }
  },
  async created() {
    await this.authenticateAndFetchUser();
  },
  computed: {
    filteredNotifs() {
        if (this.filter === 'unread') {
          return this.displayNotifs.filter(notification => !notification.read);
        }
        return this.displayNotifs;
      },
    filteredMenuItems() {
      return this.menuItems.filter(item => {
        if (this.isLoggedIn) {
          return item.title === '';
        } else {
          return item.title !== '';
        }
      });
    }
  },
  beforeUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
};
</script>

<style scoped>
.navbar-toolbar {
  background: linear-gradient(to right, rgb(84, 51, 255), rgb(32, 189, 255), rgb(165, 254, 203)) !important;
  display: flex;
  align-items: center;
  position: relative;
  color: white;
}

.navbar-logo-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.text--primary--text {
  color: cyan !important;
}
.font-italic {
  font-style: italic !important;
}

.navbar-logo {
  max-width: 100%;
  height: auto;
  max-height: 50px;
}

@media (max-width: 600px) {
  .navbar-logo {
    max-height: 60px;
  }

  .navbar-logo-container {
    bottom: 5px;
  }
}

@media (max-width: 400px) {
  .navbar-logo {
    max-height: 60px;
  }

  .navbar-logo-container {
    bottom: 0;
  }
}

.custom-badge {
  top: -4px;
}
</style>
