import { createApp } from 'vue';
import App from './components/NavBar.vue';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css';
import { fr } from 'vuetify/locale'
import {router} from "@/routes";

const vuetify = createVuetify({
    components,
    directives,
    locale: {
        locale: 'fr',
        messages: { fr },
    },
})

const app = createApp(App);

app.use(router);
app.use(vuetify);

app.mount('#app');

router.beforeEach((to, from, next) => {
    document.title = to.meta?.title;
    const faviconPath = '/alhambradote_favicon/favicon.ico';
    let link = document.querySelector("link[rel*='icon']");

    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.type = 'image/x-icon';
    link.href = faviconPath;

    const accessToken = localStorage.getItem('access');

    const restrictedNames = ['Login', 'Register', 'ForgotPassword','ResetPasswordConfirmation','Home'];

    if (!restrictedNames.includes(to.name) && !accessToken) {
        next({ name: 'Login' });
    } else {
        next();
    }
});
