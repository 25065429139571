<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col class="text-center mb-4 d-flex justify-center">
        <v-menu offset-y activator="parent">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">
              <v-icon color="white" left>mdi-account-multiple-plus-outline</v-icon>
              Choisir une commission
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="showDialog = true" class="py-1 text-center justify-center">
              <v-list-item-title>Ajouter une commission</v-list-item-title>
            </v-list-item>
            <v-list-item @click="temporaryCommission = true" class="py-1 text-center justify-center">
              <v-list-item-title>Ajouter une commission temporaire</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-dialog v-model="showDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addCommission">
              <v-text-field
                  ref="nameField"
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission"
                  :rules="nameCommission"
                  v-model="commissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  v-model="selectedCommiteeOptionImage"
                  :items="options"
                  item-title="text"
                  item-value="value"
                  label="Choisir une option"
                  outlined
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #title>
                      <span class="custom-image-wrapper">
                        <img :src="item.raw.logo" class="custom-image"  alt=""/>
                      </span>
                      <span>{{ item.raw.text }}</span>
                    </template>
                  </v-list-item>
                </template>

              </v-select>

              <div v-if="selectedCommiteeOptionImage === 'v-select'">
                <v-select
                    :items="defaultImages"
                    item-title="title"
                    item-value="id"
                    v-model="selectedImageCommiteeId"
                    class="custom-select"
                >
                  <template #item="{ item, props }">
                    <v-list-item v-bind="props" class="custom-list-item">
                      <template #title>
                        <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image"  alt=""/>
                        </span>
                          <span class="custom-item-text">{{ item.title }}</span>
                        </div>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <div v-else-if="selectedCommiteeOptionImage === 'input-file'">
                <v-file-input label="File input" v-model="selectedCommiteeCustomImage" variant="solo" accept="image/*"></v-file-input>
              </div>

              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="message"
                  :type="isCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="showDialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="temporaryCommission" max-width="500">
        <v-card>
          <v-card-title class="headline">Ajouter une Commission Temporaire</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="addTemporaryCommission">
              <v-text-field
                  ref="nameField"
                  label="Nom Commission"
                  placeholder="Entrez le nom de la commission temporaire"
                  :rules="nameCommissionTemp"
                  v-model="temporaryCommissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  v-model="selectedTemporaryCommiteeOptionImage"
                  :items="options"
                  item-title="text"
                  item-value="value"
                  label="Choisir une option"
                  outlined
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #title>
                      <span class="custom-image-wrapper">
                        <img :src="item.raw.logo" class="custom-image"  alt=""/>
                      </span>
                      <span>{{ item.raw.text }}</span>
                    </template>
                  </v-list-item>
                </template>

              </v-select>

              <div v-if="selectedTemporaryCommiteeOptionImage === 'v-select'">
                <v-select
                    :items="defaultImages"
                    item-title="title"
                    item-value="id"
                    v-model="selectedImageTemporaryId"
                    class="custom-select"
                >
                  <template #item="{ item, props }">
                    <v-list-item v-bind="props" class="custom-list-item">
                      <template #title>
                        <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image"  alt=""/>
                        </span>
                          <span class="custom-item-text">{{ item.title }}</span>
                        </div>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <div v-else-if="selectedTemporaryCommiteeOptionImage === 'input-file'">
                <v-file-input label="File input" v-model="selectedTemporaryCommiteeCustomImage" variant="solo" accept="image/*"></v-file-input>
              </div>
              <v-text-field
                  v-model="startDate"
                  type="date"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="startTime"
                  label="Heure de début"
                  type="time"
                  prepend-icon="mdi-clock"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endDate"
                  type="date"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="endTime"
                  label="Heure de fin"
                  type="time"
                  prepend-icon="mdi-clock"
                  @change="validateTimes"
                  required
              ></v-text-field>
              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
                  :disabled="isErrorDateMessage"
              >
                Ajouter
              </v-btn>
              <v-alert
                  v-if="isErrorDateMessage"
                  type="error"
                  dismissible
              >
                {{ errorDateMessage }}
              </v-alert>
              <v-alert
                  v-if="message && !isErrorDateMessage"
                  :type="isTemporaryCommissionCreated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="temporaryCommission = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="showEditDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Modifier une Commission</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updateCommission">
            <v-text-field
                label="Nom Commission"
                :rules="nameCommissionModif"
                v-model="editedCommissionName"
                prepend-inner-icon="mdi-account-group"
                variant="outlined"
                density="compact"
                required
            ></v-text-field>

            <v-select
                v-model="editedCommiteeOptionImage"
                :items="options"
                item-title="text"
                item-value="value"
                label="Choisir une option"
                outlined
            >
              <template #item="{ item, props }">
                <v-list-item v-bind="props">
                  <template #title>
                      <span class="custom-image-wrapper">
                        <img :src="item.raw.logo" class="custom-image"  alt=""/>
                      </span>
                    <span>{{ item.raw.text }}</span>
                  </template>
                </v-list-item>
              </template>

            </v-select>

            <div v-if="editedCommiteeOptionImage === 'v-select'">
              <v-select
                  :items="defaultImages"
                  item-title="title"
                  item-value="id"
                  v-model="editedImageCommiteeId"
                  class="custom-select"
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props" class="custom-list-item">
                    <template #title>
                      <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image"  alt=""/>
                        </span>
                        <span class="custom-item-text">{{ item.title }}</span>
                      </div>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
            </div>

            <div v-else-if="editedCommiteeOptionImage === 'input-file'">
              <v-file-input label="File input" v-model="editedCommiteeCustomImage" variant="solo" accept="image/*"></v-file-input>
            </div>
            <v-btn block class="mb-8" color="blue" size="large" variant="tonal" type="submit">
              Modifier
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showEditDialog = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialog" max-width="500">
      <v-card>
        <v-alert
            v-if="isMessage"
            type="error"
            class="mt-4"
        >
          {{ message }}
          <v-progress-linear
              v-if="progress < 100"
              :model-value="progress"
              color="deep-orange"
              height="10"
              striped
          ></v-progress-linear>
        </v-alert>
        <v-card-title class="headline">Confirmation de suppression</v-card-title>
        <v-card-text>
          Êtes-vous sûr de vouloir supprimer cette commission ?
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" @click="confirmDeleteCommitee">Supprimer</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDeleteDialog = false">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto">
          <v-card-title class="text-center">
            <span>Commissions</span>
          </v-card-title>
          <v-list>
            <v-list-item
                v-for="item in createdCommissions"
                :key="item.id"
                class="d-flex align-center justify-between"
            >
              <v-list-item-avatar>
                <v-img class="text-center" :src="item.prependAvatar" width="50" height="50"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-center">{{ item.title }}</v-list-item-title>
              <div class="text-center">
                <v-btn icon @click="editCommission(item)">
                  <v-icon color="blue">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="showDeleteConfirmCommitee(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <v-dialog v-model="showEditTemporaryDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">Modifier une Commission Temporaire</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="updateTemporaryCommission">
              <v-text-field
                  label="Nom Commission"
                  :rules="nameCommissionTempModif"
                  v-model="editedTemporaryCommissionName"
                  prepend-inner-icon="mdi-account-group"
                  variant="outlined"
                  density="compact"
                  required
              ></v-text-field>

              <v-select
                  v-model="editedTemporaryCommiteeOptionImage"
                  :items="options"
                  item-title="text"
                  item-value="value"
                  label="Choisir une option"
                  outlined
              >
                <template #item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template #title>
                      <span class="custom-image-wrapper">
                        <img :src="item.raw.logo" class="custom-image"  alt=""/>
                      </span>
                      <span>{{ item.raw.text }}</span>
                    </template>
                  </v-list-item>
                </template>

              </v-select>

              <div v-if="editedTemporaryCommiteeOptionImage === 'v-select'">
                <v-select
                    :items="defaultImages"
                    item-title="title"
                    item-value="id"
                    v-model="editedImageTemporaryId"
                    class="custom-select"
                >
                  <template #item="{ item, props }">
                    <v-list-item v-bind="props" class="custom-list-item">
                      <template #title>
                        <div class="custom-item-content">
                        <span class="custom-image-wrapper">
                          <img :src="item.raw.prependAvatar" class="custom-image"  alt=""/>
                        </span>
                          <span class="custom-item-text">{{ item.title }}</span>
                        </div>
                      </template>
                    </v-list-item>
                  </template>
                </v-select>
              </div>

              <div v-else-if="editedTemporaryCommiteeOptionImage === 'input-file'">
                <v-file-input label="File input" v-model="editedTemporaryCommiteeCustomImage" variant="solo" accept="image/*"></v-file-input>
              </div>


              <v-text-field
                  v-model="editedStartDate"
                  type="date"
                  label="Date de début"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="editedStartTime"
                  label="Heure de début"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="editedEndDate"
                  type="date"
                  label="Date de fin"
                  prepend-icon="mdi-calendar"
                  @change="validateTimes"
                  required
              ></v-text-field>

              <v-text-field
                  v-model="editedEndTime"
                  label="Heure de fin"
                  type="time"
                  prepend-icon="mdi-clock"
                  required
              ></v-text-field>

              <v-btn
                  block
                  class="mb-8"
                  color="blue"
                  size="large"
                  variant="tonal"
                  type="submit"
                  :disabled="isErrorDateMessage"
              >
                Modifier
              </v-btn>

              <v-alert
                  v-if="isErrorDateMessage"
                  type="error"
                  dismissible
              >
                {{ errorDateMessage }}
              </v-alert>

              <v-alert
                  v-if="message && !isErrorDateMessage"
                  :type="isTemporaryCommissionUpdated ? 'success' : 'error'"
                  class="mt-4"
              >
                {{ message }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showEditTemporaryDialog = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDeleteTempCommiteeDialog" max-width="500">
        <v-card>
          <v-alert
              v-if="isMessage"
              type="error"
              class="mt-4"
          >
            {{ message }}
            <v-progress-linear
                v-if="progress < 100"
                :model-value="progress"
                color="deep-orange"
                height="10"
                striped
            ></v-progress-linear>
          </v-alert>
          <v-card-title class="headline">Confirmation de suppression</v-card-title>
          <v-card-text>
            Êtes-vous sûr de vouloir supprimer cette commission temporaire ?
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" @click="confirmDeleteTempCommitee">Supprimer</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="confirmDeleteTempCommiteeDialog = false">Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-col cols="12" sm="6">
        <v-card class="mx-auto">
          <v-card-title class="text-center">
            Commissions Temporaires
          </v-card-title>
          <v-list>
            <v-list-item
                v-for="item in tempCommissions"
                :key="item.id"
                class="d-flex align-center justify-between"
            >
              <v-list-item-avatar>
                <v-img :src="item.prependAvatar" width="50" height="50"></v-img>
              </v-list-item-avatar>
              <v-list-item-title class="text-center flex-grow-1">{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>
                Début : {{ getSplittedDate(item.startDate.date) }} {{ getSplittedTime(item.startDate.date) }} - Fin : {{ getSplittedDate(item.endDate.date) }} {{ getSplittedTime(item.endDate.date) }}
              </v-list-item-subtitle>
              <v-btn icon @click="openEditTempCommiteeDialog(item)">
                <v-icon color="blue">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="openDeleteTempCommiteeDialog(item)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import {ref, onMounted, watch} from 'vue';
import {checkAuthentication} from "@/LoginCheck";
import axios from 'axios';
import urls from "@/config/config";

const defaultImages = ref([
  { id: 1, prependAvatar: '/img/animation.png', title: 'spectacle', isUploaded:false},
  { id: 2, prependAvatar: '/img/communication.png', title: 'partage', isUploaded:false},
  { id: 3, prependAvatar: '/img/finance.png', title: 'finance', isUploaded:false},
  { id: 4, prependAvatar: '/img/restauration.png', title: 'restauration', isUploaded:false},
  { id: 5, prependAvatar: '/img/global-communication.png', title: 'monde', isUploaded:false},
]);

const nameCommission = ref([
  value => {
    if (value) return true;
    return 'Entrez votre nom de commission';
  },
]);
const nameCommissionTemp = ref([
  value => {
    if (value) return true;
    return 'Entrez votre nom de commission temporaire';
  },
]);
const nameCommissionModif = ref([
value => {
  if (value) return true;
  return 'Entrez votre nom de commission';
  },
]);
const nameCommissionTempModif = ref([
  value => {
    if (value) return true;
    return 'Entrez votre nom de commission';
  },
]);
const showDialog = ref(false);
const deleteTempCommitee = ref(null)
const selectedCommiteeCustomImage = ref(null)
const selectedTemporaryCommiteeCustomImage = ref(null)
const editedCommiteeCustomImage = ref(null)
const editedTemporaryCommiteeCustomImage = ref(null)
const deleteCommitee = ref(null)
const isMessage = ref(false)
const temporaryCommission = ref(false);
const commissionName = ref('');
const temporaryCommissionName = ref('');
const selectedImageCommiteeId = ref(null);
const selectedImageTemporaryId = ref(null);
const progress = ref(0);
const isTemporaryCommissionUpdated = ref(false);
const confirmDeleteTempCommiteeDialog = ref(false);
const createdCommissions = ref([]);
const tempCommissions = ref([]);
const startDate = ref('');
const startTime = ref('');
const endDate = ref('');
const endTime = ref('');
const message = ref('');
const showEditDialog = ref(false);
const confirmDeleteDialog = ref(false);
const editedCommission = ref(null);
const editedCommissionName = ref('');
const editedImageCommiteeId = ref('');
const temporaryCommiteeId = ref(null);
const showEditTemporaryDialog = ref(false);
const editedTemporaryCommissionName = ref('');
const editedImageTemporaryId = ref('');
const editedStartDate = ref('');
const editedStartTime = ref('');
const editedEndDate = ref('');
const editedEndTime = ref('');
const currentEmail = ref('');
const isCommissionCreated = ref(false);
const isTemporaryCommissionCreated = ref(false);
const isErrorDateMessage = ref(false);
const errorDateMessage = ref('');
const selectedCommiteeOptionImage = ref("");
const selectedTemporaryCommiteeOptionImage = ref("");
const editedCommiteeOptionImage = ref("");
const editedTemporaryCommiteeOptionImage = ref("");
const options = ref([
  { value: 'v-select', logo : '/img/logo.png',text: 'Choisir des logos par défaut' },
  { value: 'input-file', logo:  '/img/custom-image.png',text: 'Choisir une image personnalisée' }
]);

onMounted(async () => {
  await fetchCommitees();
  console.log("value isMessage before progress",isMessage.value)
  if (isMessage.value) {
    startProgress();
  }
});

watch(isMessage, (newValue) => {
  console.log('isMessage changed to:', newValue);
  if (newValue) {
    startProgress();
  }
});

const updateTemporaryCommission = async () => {

  try {
    if (
        !editedTemporaryCommissionName.value ||
        !editedStartDate.value ||
        !editedStartTime.value ||
        !editedEndTime.value ||
        !editedEndDate.value ||
        !currentEmail.value
    ) {
      errorDateMessage.value = 'Veuillez remplir tous les champs.';
      isErrorDateMessage.value = true;

      setTimeout(() => {
        errorDateMessage.value = '';
        isErrorDateMessage.value = false;
      }, 6000);

      return;
    }

    const payload = {
      title: editedTemporaryCommissionName.value,
      prependAvatar: null,
      startDate: editedStartDate.value,
      startTime: editedStartTime.value,
      endTime: editedEndTime.value,
      endDate: editedEndDate.value,
      user_email: currentEmail.value,
    };

    const avatar = getPrependAvatarById(editedImageTemporaryId.value);
    if (avatar == null) {
      if(editedTemporaryCommiteeCustomImage.value)
      {
        await uploadImage(editedTemporaryCommiteeCustomImage.value);
      }

      payload.prependAvatar = '/img/' + editedTemporaryCommiteeCustomImage.value.name;
    } else {
      payload.prependAvatar = getPrependAvatarById(editedImageTemporaryId.value);
    }

    const response = await axios.put(urls.getApiUpdateTemporaryCommitee(temporaryCommiteeId.value), payload);
    console.log('Temporary committee updated successfully:', response.data);
    isTemporaryCommissionUpdated.value = true;
    showEditTemporaryDialog.value = false;
    await fetchCommitees();

  } catch (error) {

    errorDateMessage.value = 'Erreur lors de la mise à jour de la commission.';
    isErrorDateMessage.value = true;

    setTimeout(() => {
      errorDateMessage.value = '';
      isErrorDateMessage.value = false;
    }, 6000);
  }
};

const uploadImage = async (targettedImageUpload) => {

  const formData = new FormData();
  formData.append('image', targettedImageUpload);

  try {
    const response = await axios.post(urls.upload_custom_image, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.status === 200) {
      console.log("uploaded successfullly")
    }
  } catch (error) {
    console.log('Error: ' + error.message);
  }
};

const getSplittedDate = (date) => {
  return date.split(' ')[0];
};

const getSplittedTime = (date) => {
  const timePart = date.split(' ')[1];
  return timePart.split('.')[0];
};

const editCommission = (commission) => {
  editedCommission.value = commission;
  editedCommissionName.value = commission.title;
  if(defaultImages.value.some(image => image.prependAvatar === commission.prependAvatar))
  {
    editedCommiteeOptionImage.value = "v-select";
    editedImageCommiteeId.value = commission.prependAvatar;
  }else{
    editedCommiteeOptionImage.value = "input-file";
    editedCommiteeCustomImage.value = commission.prependAvatar;
  }
  showEditDialog.value = true;
};

const updateCommission = async () => {
  if (editedCommissionName.value && (editedImageCommiteeId.value || editedCommiteeCustomImage.value)) {
    if (editedCommiteeCustomImage.value) {
      await uploadImage(editedCommiteeCustomImage.value);
    }

    console.log(editedImageCommiteeId.value);
    console.log(editedCommiteeCustomImage.value);
    const updatedCommission = {
      id: editedCommission.value.id,
      title: editedCommissionName.value,
      prependAvatar: null,

      user_email: currentEmail.value,
    };

    const avatar = getPrependAvatarById(editedImageCommiteeId.value);
    if (avatar == null) {
      updatedCommission.prependAvatar = '/img/' + editedCommiteeCustomImage.value.name;
    } else {
      updatedCommission.prependAvatar = getPrependAvatarById(editedImageCommiteeId.value);
    }

    console.log(updatedCommission.prependAvatar)

    try {
      const response = await axios.put(
          urls.getApiUpdateCommitee(updatedCommission.id),
          updatedCommission
      );

      if (response.data.success) {
        console.log("Commission updated successfully");
        await fetchCommitees();
        message.value = 'Commission mise à jour avec succès.';
      } else {
        console.log("Failed to update commission");
        message.value = 'Erreur lors de la mise à jour de la commission.';
      }
    } catch (error) {
      console.log("Error updating commission:", error);
      message.value = 'Erreur lors de la mise à jour de la commission.';
    }

    showEditDialog.value = false;
  } else {
    message.value = 'Veuillez remplir tous les champs.';
  }
};


const showDeleteConfirmCommitee = (commission) => {
  deleteCommitee.value = commission;
  confirmDeleteDialog.value = true;
};

const confirmDeleteTempCommitee = async () => {
  console.log("in temp data before delete", deleteTempCommitee.value)
  console.log("in temp remove", deleteTempCommitee.value.id)
  await axios.delete(urls.getApiDeleteTemporaryCommitee(deleteTempCommitee.value.id), {
    params: { user_email: currentEmail.value }
  })
      .then(response => {
        if (response.data.success) {
          console.log("delete completed")
            message.value = response.data.message;
            isMessage.value = false;
          confirmDeleteTempCommiteeDialog.value = false;
          fetchCommitees();
        }
      })
      .catch(error => {
        isMessage.value = true;
        message.value = error.response.data.message;
      });
};

const confirmDeleteCommitee = async () => {
  console.log("commitee data before delete", deleteCommitee.value)
  console.log("commitee id",deleteCommitee.value.id)
  await axios.delete(urls.getApiDeleteCommitee(deleteCommitee.value.id),{
    params: { user_email: currentEmail.value }
  })
      .then(response => {
        if (response.data.success) {
          console.log("delete commitee completed")
          confirmDeleteDialog.value = false;
          message.value = response.data.message;
          isMessage.value = false;
          fetchCommitees();
        }
      })
      .catch(error => {
        console.log(error)
        message.value = error.response.data.message;
        isMessage.value = true;
      });
};

const fetchCommitees = async () => {
  message.value = "";
    try {
      const response = await checkAuthentication();
      currentEmail.value = response.email;
    } catch (error) {
      console.log("Erreur lors de l'authentification:", error);
    }
    console.log("current user email",currentEmail.value)
    axios.get(urls.get_commitees, {
      params: {
        user_email: currentEmail.value
      }
    })
        .then(response => {
          console.log(response.data)
          if (response.data.success) {
            createdCommissions.value = response.data.commitees
            tempCommissions.value = response.data.temporaryCommitees
            console.log("temp commitee",tempCommissions.value)
          }
        })
        .catch(error => {
          console.error('Error:', error);
          message.value = 'Erreur lors de la création de la récupération des data commission';
        });
};

const getPrependAvatarById = (id) => {
  const image = defaultImages.value.find(img => img.id === id);
  return image ? image.prependAvatar : null;
}

const addCommission = async () => {
  if (commissionName.value && (selectedImageCommiteeId.value || selectedCommiteeCustomImage.value) ){

    if(selectedCommiteeCustomImage.value)
    {
      await uploadImage(selectedCommiteeCustomImage.value);
    }

    try {
      const newCommission = {
        title: commissionName.value,
        prependAvatar: selectedImageCommiteeId.value
            ? getPrependAvatarById(selectedImageCommiteeId.value)
            : '/img/' + selectedCommiteeCustomImage.value.name,
        user_email: currentEmail.value
      };
      await axios.post(urls.create_commitee, newCommission)
          .then(response => {
            if (response.data.success) {
              console.log("commitee added",response.data.message)
              fetchCommitees()
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });

      commissionName.value = '';
      selectedImageCommiteeId.value = null;
      showDialog.value = false;
      isCommissionCreated.value = true;
    } catch (error) {
      message.value = 'Erreur lors de l\'ajout de la commission.';
      isCommissionCreated.value = false;
    }
  } else {
    message.value = 'Veuillez remplir tous les champs.';
    isCommissionCreated.value = false;

    setTimeout(() => {
      message.value = '';
      isCommissionCreated.value = true;
    }, 6000);
  }
};

const startProgress = () => {
  progress.value = 0;
  const interval = setInterval(() => {
    console.log("progress bar value",progress.value);
    progress.value += 2;
    if (progress.value >= 100) {
      clearInterval(interval);
      isMessage.value = false;
      message.value = '';
    }
  }, 100);
};

const addTemporaryCommission = async () => {
  if (
      temporaryCommissionName.value &&
      startDate.value &&
      startTime.value &&
      endDate.value &&
      endTime.value &&
      (selectedImageTemporaryId.value || selectedTemporaryCommiteeCustomImage.value)
  ) {
    if(selectedTemporaryCommiteeCustomImage.value)
    {
      await uploadImage(selectedTemporaryCommiteeCustomImage.value);
    }

    if (new Date(`${startDate.value}T${startTime.value}`) >= new Date(`${endDate.value}T${endTime.value}`)) {
      errorDateMessage.value = 'La date de fin doit être après la date de début.';
      isErrorDateMessage.value = true;
      return;
    }

    try {
      const newTempCommission = {
        title: temporaryCommissionName.value,
        prependAvatar: selectedImageTemporaryId.value
            ? getPrependAvatarById(selectedImageTemporaryId.value)
            : '/img/' + selectedTemporaryCommiteeCustomImage.value.name,
        user_email: currentEmail.value,
        startDate: startDate.value,
        startTime: startTime.value,
        endDate: endDate.value,
        endTime: endTime.value
      };
      await axios.post(urls.create_temporary_commitee, newTempCommission)
          .then(response => {
            if (response.data.success) {
              console.log("commitee temp added",response.data.message)
              fetchCommitees()
            } else {
              message.value = 'Erreur lors de l\'envoi de message';
            }
          })
          .catch(error => {
            console.error('Error:', error);
            message.value = 'Erreur lors de l\'envoi de message';
          });
      temporaryCommissionName.value = '';
      startDate.value = '';
      startTime.value = '';
      endDate.value = '';
      endTime.value = '';
      selectedImageTemporaryId.value = null;
      temporaryCommission.value = false;
      isTemporaryCommissionCreated.value = true;
      isErrorDateMessage.value = false;
    } catch (error) {
      errorDateMessage.value = 'Erreur lors de l\'ajout de la commission temporaire.';
      isErrorDateMessage.value = true;
    }
  } else {
    errorDateMessage.value = 'Veuillez remplir tous les champs.';
    isErrorDateMessage.value = true;

    setTimeout(() => {
      errorDateMessage.value = '';
      isErrorDateMessage.value = false;
    }, 6000);
  }
};

const getTimeValues = (isEditMode) => {
  if (isEditMode) {
    return {
      startDateValue: editedStartDate.value,
      endDateValue: editedEndDate.value,
      startTimeValue: editedStartTime.value,
      endTimeValue: editedEndTime.value
    };
  }
  return {
    startDateValue: startDate.value,
    endDateValue: endDate.value,
    startTimeValue: startTime.value,
    endTimeValue: endTime.value
  };
};

const validateTimes = () => {
  const isEditMode = editedStartDate.value && editedEndDate.value && editedEndTime.value && editedStartTime.value;
  const isAddMode = startDate.value && endDate.value && endTime.value && startTime.value;

  if (isAddMode || isEditMode) {
    const { startDateValue, endDateValue, startTimeValue, endTimeValue } = getTimeValues(isEditMode);

    console.log("start date", startDateValue);
    console.log('end date', endDateValue);

    const now = new Date();

    const [startHour, startMinute] = startTimeValue.split(':').map(Number);
    const start = new Date(`${startDateValue}T${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`);

    const [endHour, endMinute] = endTimeValue.split(':').map(Number);
    const end = new Date(`${endDateValue}T${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`);

    console.log("current date and time", now.toString());
    console.log("start date and time", start.toString());
    console.log("end date and time", end.toString());

    if (start < now || end < now) {
      isErrorDateMessage.value = true;
      console.log("is error", isErrorDateMessage.value);
      errorDateMessage.value = "Les dates ne peuvent pas être inférieures à la date courante.";
    }
    else if (end < start) {
      isErrorDateMessage.value = true;
      console.log("is error", isErrorDateMessage.value);
      errorDateMessage.value = "L'heure de fin doit être après l'heure de début.";
    }
    else {
      console.log('dates correctes');
      isErrorDateMessage.value = false;
      errorDateMessage.value = '';
    }
  }
  
 setTimeout(() => {
    errorDateMessage.value = '';
    isErrorDateMessage.value = false;
  }, 6000);
};

watch([startDate, endDate, endTime, startTime, editedStartDate, editedEndDate, editedEndTime, editedStartTime], validateTimes);

const openEditTempCommiteeDialog = (item) => {
  editedTemporaryCommissionName.value = item.title;
  editedStartDate.value = getSplittedDate(item.startDate.date);
  editedStartTime.value = getSplittedTime(item.startDate.date);
  editedEndDate.value = getSplittedDate(item.endDate.date);
  editedEndTime.value = getSplittedTime(item.endDate.date);
  temporaryCommiteeId.value = item.id;

  if(defaultImages.value.some(image => image.prependAvatar === item.prependAvatar))
  {
    editedTemporaryCommiteeOptionImage.value = "v-select";
    editedImageTemporaryId.value = item.prependAvatar;
  }else{
    editedTemporaryCommiteeOptionImage.value = "input-file";
    editedTemporaryCommiteeCustomImage.value = item.prependAvatar;
  }

  showEditTemporaryDialog.value = true;
};

const openDeleteTempCommiteeDialog = (item) => {
  deleteTempCommitee.value = item;
  confirmDeleteTempCommiteeDialog.value = true;
};
</script>

<style scoped>

.custom-select {
  display: flex;
  align-items: center;
}

.custom-list-item .custom-item-content {
  display: flex;
  align-items: center;
}

.custom-image-wrapper {
  margin-right: 8px;
}

.custom-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.custom-item-text {
  font-size: 14px;
}
</style>
