<template>
    <h1 class="text-center">Page non trouvé</h1>
    <p class="text-center">Cette page n'existe pas.</p>
    <router-link class="text-decoration-none container" to="/login">
      <i class="mdi mdi-arrow-right"></i> Revenir à la page de connexion
    </router-link>
</template>

<style>
.container {
  display: flex;
  justify-content: center;
  background: linear-gradient(to right, rgb(84, 51, 255), rgb(32, 189, 255), rgb(165, 254, 203));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: color 0.3s, background-color 0.3s;
}
</style>