<template>
  <v-container>
    <v-card-title>
      <v-typography variant="h3" class="mb-4 text-center d-flex justify-center">
        Liste des utilisateurs
      </v-typography>
    </v-card-title>

    <div class="d-flex justify-center">
      <v-btn
          color="primary"
          class="mb-4"
          @click="addDialog = true"
      >
        Ajouter un utilisateur
      </v-btn>
    </div>

    <v-data-table
        :items="users"
        item-value="email"
        class="elevation-1"
        loading-text="Chargement en cours..."
        :loading="isLoading"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.id }}</td>
          <td>{{ item.Prénom }}</td>
          <td>{{ item.Nom }}</td>
          <td>{{ item.Email }}</td>
          <td>{{ item.Rôle }}</td>
          <td>
            <v-icon
                small
                class="mr-2"
                @click="editUser(item)"
                color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="confirmDeleteUser(item)"
                color="red"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="addDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">Ajouter un utilisateur</v-card-title>
        <v-card-text>
          <v-alert
              v-if="emailExists"
              text="L'email est déjà utilisé. Utilisez une autre adresse mail"
              title="Problème d'email"
              type="error"
          >
            <v-progress-linear
                v-if="progress < 100"
                :model-value="progress"
                color="deep-orange"
                height="10"
                striped
            ></v-progress-linear>
          </v-alert>
          <v-form ref="form">
            <v-text-field
                v-model="newUser.name"
                :rules ="nameRules"
                label="Prénom"
                required
                prepend-inner-icon="mdi-account-outline"
            ></v-text-field>
            <v-text-field
                v-model="newUser.lastname"
                :rules="lastNameRules"
                label="Nom"
                required
                prepend-inner-icon="mdi-account"
            ></v-text-field>
            <v-text-field
                v-model="newUser.email"
                :rules="emailRules"
                label="Email"
                type="email"
                required
                prepend-inner-icon="mdi-email"
            ></v-text-field>
            <v-text-field
                v-model="newUser.password"
                :rules="passwordRules"
                label="Mot de passe"
                :type="visible ? 'text' : 'password'"
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-inner="visible = !visible"
                prepend-inner-icon="mdi-lock-outline"
            ></v-text-field>
            <v-select
                v-model="newUser.role"
                label="Choisir un rôle"
                :items="[role_normal, role_privilege, role_admin]"
                prepend-inner-icon="mdi-account-group"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-alert
            v-if="isPopUpErrorMessage"
            type="error"
            text
        >
          {{ errorMessage }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="addDialog = false">Annuler</v-btn>
          <v-btn color="green darken-1" text @click="addUser">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">Confirmer la suppression</v-card-title>

        <v-card-text>
          <v-alert
              v-if="isPopUpErrorMessage"
              type="error"
              text
              class="mb-4"
          >
            {{ errorMessage }}
          </v-alert>

          Êtes-vous sûr de vouloir supprimer l'utilisateur {{ userToDelete.name }} ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="red darken-1" text @click="deleteUserConfirmed">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-alert
        v-if="isUserDetailsIssue"
        type="error"
        dismissible
        class="mt-4"
    >
      {{ errorMessage }}
    </v-alert>

    <v-dialog
        v-model="editDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">Modifier un utilisateur</v-card-title>
        <v-card-text>
          <v-alert
              v-if="emailExists"
              text="L'email est déjà utilisé. Utilisez une autre adresse mail"
              title="Problème d'email"
              type="error"
          >
            <v-progress-linear
                v-if="progress < 100"
                :model-value="progress"
                color="deep-orange"
                height="10"
                striped
            ></v-progress-linear>
          </v-alert>
          <v-form ref="form">
            <div class="text-subtitle-1 text-medium-emphasis">Prénom</div>
            <v-text-field v-model="currentUser.Prénom" :rules="nameRules" label="Prénom" placeholder="Entrez votre prénom" outlined required prepend-inner-icon="mdi-account" variant="outlined" dense></v-text-field>
            <div class="text-subtitle-1 text-medium-emphasis">Nom</div>
            <v-text-field v-model="currentUser.Nom" :rules= "lastNameRules" label="Nom" placeholder="Entrez votre nom" outlined required prepend-inner-icon="mdi-account-outline" variant="outlined" dense></v-text-field>
            <div class="text-subtitle-1 text-medium-emphasis">Email</div>
            <v-text-field v-model="currentUser.Email" :rules="emailRules" label="Email" placeholder="Entrez votre email" outlined required prepend-inner-icon="mdi-email" type="email" variant="outlined" dense></v-text-field>
            <div class="text-subtitle-1 text-medium-emphasis">Mot de passe</div>
            <v-text-field label="Mot de passe" v-model="currentUser.password" :rules="passwordRules" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" :type="visible ? 'text' : 'password'" density="compact" placeholder="Entrez votre mot de passe" prepend-inner-icon="mdi-lock-outline" variant="outlined" @click:append-inner="visible = !visible"></v-text-field>
            <div class="text-subtitle-1 text-medium-emphasis">Rôle</div>
            <v-select
                label="Choisir un rôle"
                :items="[role_normal,role_privilege,role_admin]"
                variant="outlined"
                v-model="currentUser.Rôle"
                prepend-inner-icon="mdi-account-group"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-alert
            v-if="isPopUpErrorMessage"
            type="error"
            text
        >
          {{ errorMessage }}
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">Annuler</v-btn>
          <v-btn color="green darken-1" text @click="updateUser">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import urls from "@/config/config";
import {checkAuthentication} from "@/LoginCheck";


export default {
  name: 'UserList',
  data() {
    return {
      visible: false,
      isLoading:true,
      users: [],
      dialog: false,
      editDialog: false,
      addDialog: false,
      emailExists:false,
      isPopUpErrorMessage:false,
      isUserDetailsIssue:false,
      progress: 0,
      userToDelete: null,
      currentUser: {},
      newUser: {},
      errorMessage: '',
      role_admin: "",
      role_privilege:"",
      role_normal:"",
      isErrorDateMessage: false,
      errorDateMessage: "",
      nameRules: [
        value => {
          if (value) return true
          return 'Entrez votre prénom'
        },
      ],
      lastNameRules: [
        value => {
          if (value) return true
          return 'Entrez votre nom de famille'
        },
      ],
      emailRules: [
        value => {
          if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true

          return 'Entrez une adresse mail valide'
        },
      ],
      passwordRules: [
        value => {
          if (value) return true
          return 'Entrez votre mot de passe'
        },
      ],
    };
  },
  watch: {
    emailExists(newValue) {
      if (newValue) {
        this.startProgress();
      }
    },
  },
  mounted() {
    this.fetchUsers();
    this.getRoles();
    if (this.emailExists) {
      this.startProgress();
    }
  },
  methods: {
    startProgress() {
      this.progress = 0;
      const interval = setInterval(() => {
        console.log("progress bar value",this.progress);
        this.progress += 2;
        if (this.progress >= 100) {
          clearInterval(interval);
          this.emailExists = false;
        }
      }, 100);
    },
    getRoles() {
      axios.get(urls.get_user_roles)
          .then(response => {
            this.role_normal = response.data[0]['value'];
            this.role_admin = response.data[1]['value'];
            this.role_privilege = response.data[2]['value'];

            console.log(this.role_admin,this.role_normal,this.role_privilege)
          })
          .catch(error => {
            console.error(error);
          });
    },
    async fetchUsers() {
      try {
        const response = await checkAuthentication();
        this.isLoggedIn = true;
        this.userEmail = response.email;
      } catch (error) {
        console.log("Erreur lors de l'authentification:", error);
      }

      try {
        const response = await axios.get(urls.get_users,{
          params: { user_email: this.userEmail }
        });
        this.users = response.data;
        this.users = this.users.map(dessert => ({
          ...dessert,
          Actions: ['modifier','supprimer']
        }));
        console.log(this.users)
        if(response.data){
          this.isLoading = false;
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs :', error);
      }
    },
    addUser() {
      if (
          !this.newUser.lastname ||
          !this.newUser.name ||
          !this.newUser.password ||
          !this.newUser.email ||
          !this.newUser.role
      ) {
        this.errorMessage = "Veuillez remplir tous les champs.";
        this.isPopUpErrorMessage = true;

        setTimeout(() => {
          this.errorMessage = '';
          this.isPopUpErrorMessage = false;
        }, 6000);
        return;
      }

      axios.post(urls.add_user, {
        lastname: this.newUser.lastname,
        name: this.newUser.name,
        password: this.newUser.password,
        email: this.newUser.email,
        role: this.newUser.role,
        user_email: this.userEmail
      })
          .then(response => {
            this.emailExists = false;
            this.addDialog = false;
            this.fetchUsers();
            console.log("success", response);
          })
          .catch(error => {
            console.error('in error register:', error);
            if (error.response && error.response.data.message === "email déjà utilisé") {
              this.emailExists = true;
            } else {
              this.errorMessage = "Erreur lors de l'ajout de l'utilisateur.";
              this.isPopUpErrorMessage = true;

              setTimeout(() => {
                this.errorMessage = '';
                this.isPopUpErrorMessage = false;
              }, 6000);
            }
          });
    },
    async updateUser() {
      if (
          !this.currentUser.Prénom ||
          !this.currentUser.Nom ||
          !this.currentUser.password ||
          !this.currentUser.Email ||
          !this.currentUser.Rôle
      ) {
        this.errorMessage = "Veuillez remplir tous les champs.";
        this.isPopUpErrorMessage = true;

        setTimeout(() => {
          this.errorMessage = '';
          this.isPopUpErrorMessage = false;
        }, 6000);
        return;
      }
      try {
        console.log("update user",this.currentUser)
        this.currentUser["user_email"] = this.userEmail;
        await axios.put(urls.getApiUpdateUser(this.currentUser.id), this.currentUser);
        this.emailExists = false;
        await this.fetchUsers();
        this.editDialog = false;
      } catch (error) {
        console.error('Erreur lors de la mise à jour de l’utilisateur :', error);
        if (error.response.data.message === "email déjà utilisé") {
          this.emailExists = true;
        }
      }
    },
    editUser(user) {
      if (user.Email === this.userEmail) {
        console.log("Vous ne pouvez pas modifier votre propre compte.");
        this.errorMessage = "Vous ne pouvez pas modifier votre propre compte.";
        this.isUserDetailsIssue = true;

        setTimeout(() => {
          this.errorMessage = "";
          this.isUserDetailsIssue = false;
        }, 3000);

        return;
      }
      this.currentUser = { ...user };
      console.log("modification utilisateur", this.currentUser);
      this.editDialog = true;
    },
    confirmDeleteUser(user) {
      this.userToDelete = user;

      if (this.userToDelete.Email === this.userEmail) {
        console.log("Vous ne pouvez pas supprimer votre propre compte.");
        this.errorMessage = "Vous ne pouvez pas supprimer votre propre compte.";
        this.isUserDetailsIssue = true;

        setTimeout(() => {
          this.errorMessage = "";
          this.isUserDetailsIssue = false;
        }, 3000);

        return;
      }
      this.dialog = true;
    },

    async deleteUserConfirmed() {
      try {
        console.log("user to delete", this.userToDelete);

        const response = await axios.delete(urls.getApiDeleteUser(this.userToDelete.id), {
          params: { user_email: this.userEmail }
        });

        if (response.data.success) {
          console.log("user deleted successfully");
          this.dialog = false;
          await this.fetchUsers();
        }
      } catch (error) {
        this.isPopUpErrorMessage = true;
        this.errorMessage = error.response?.data?.message || "Erreur lors de la suppression";

        setTimeout(() => {
          this.errorMessage = "";
          this.isPopUpErrorMessage = false;
        }, 6000);
      }
    }

  }
};
</script>
